import { createSlice } from '@reduxjs/toolkit';

export const nconsult_slice = createSlice({
    name : 'nconsult',
    initialState: {
        value: []
    },
    reducers: {
        setNconsult: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { setNconsult } = nconsult_slice.actions

export default nconsult_slice.reducer