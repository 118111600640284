import { createSlice } from '@reduxjs/toolkit';

export const company_slice = createSlice({
    name : 'company',
    initialState: {
        value: []
    },
    reducers: {
        setCompany: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { setCompany } = company_slice.actions

export default company_slice.reducer