export const nconsult_data = {
    'intro' : "Манай хуулийн фирм \"Үндэсний зөвлөгч\" зөвлөх үйлчилгээний нэг хэсэг бөгөөд \"Санхүү бизнесийн эрхзүйн судалгааны хүрээлэн\", \"Санхүүгийн зах зээлийнүндэсний зөвлөл\", \"Ай Жоб\" хүний нөөцийн зуучлалын компаниудтайгаар үйлажиллагаа явуулж байна.",
}

export const nconsult_consultants = {
    'consultants' : [
        {
            'consultant_id' : '43019e09-db35-45ec-98e0-76ff7ff08144',
            'displayName_cyrillic'   : 'Б.Тунгалагнаран',
            'firstName_cyrillic'     : 'Тунгалагнаран',
            'lastName_cyrillic'      : 'Баяраа',
            'title_cyrillic'         : 'Гүйцэтгэх захирал, Өмгөөлөгч',
            'displayName_latin'   : 'Tungalagnaran Bayaraa',
            'firstName_latin'     : 'Tungalagnaran',
            'lastName_latin'      : 'Bayaraa',
            'title_latin'         : 'CEO, Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/43019e09-db35-45ec-98e0-76ff7ff08144.png?updatedAt=1694527531378',
            
            'role'                : 'ceo',
            'role_title_cyrillic' : 'Гүйцэтгэх захирал', 
            'role_title_latin'    : 'CEO',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '9e8350f7-5e8b-44e6-95e7-156069321c58',
            'displayName_cyrillic'   : 'М.Энхжаргал',
            'firstName_cyrillic'     : 'Энхжаргал',
            'lastName_cyrillic'      : 'Махбал',
            'title_cyrillic'         : 'Өмгөөлөгч',
            'displayName_latin'   : 'Enkhjargal Makhbal',
            'firstName_latin'     : 'Enkhjargal',
            'lastName_latin'      : 'Makhbal',
            'title_latin'         : 'Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/9e8350f7-5e8b-44e6-95e7-156069321c58.png?updatedAt=1694527529596',

            'role'                : 'partner',
            'role_title_cyrillic' : 'Партнер өмгөөлөгч', 
            'role_title_latin'    : 'Partner advocate',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '70c84fc2-7945-4dc8-8495-d8c736820977',
            'displayName_cyrillic'   : 'Б.Батзориг',
            'firstName_cyrillic'     : 'Батзориг',
            'lastName_cyrillic'      : 'Бадам',
            'title_cyrillic'         : 'Хууль зүйн ухааны доктор, дэд профессор',
            'displayName_latin'   : 'Batzorig Badam',
            'firstName_latin'     : 'Batzorig',
            'lastName_latin'      : 'Badam',
            'title_latin'         : 'Doctor of Law, Associate Professor',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/70c84fc2-7945-4dc8-8495-d8c736820977.png?updatedAt=1694527529670',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Зөвлөгч', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '55ac6247-a807-46aa-a955-4dda3cba83b2',
            'displayName_cyrillic'   : 'О.Цэнгэлбаатар',
            'firstName_cyrillic'     : 'Цэнгэлбаатар',
            'lastName_cyrillic'      : 'О',
            'title_cyrillic'         : 'Стратегийн зөвлөх',
            'displayName_latin'   : 'Tsengelbaatar O',
            'firstName_latin'     : 'Tsengelbaatar',
            'lastName_latin'      : 'O',
            'title_latin'         : 'Strategy consultant',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/55ac6247-a807-46aa-a955-4dda3cba83b2.png?updatedAt=1694527529622',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Зөвлөгч', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '52ed9980-e6c8-49c9-b299-9f869ebd5ba2',
            'displayName_cyrillic'   : 'Я.Батжаргал',
            'firstName_cyrillic'     : 'Батжаргал',
            'lastName_cyrillic'      : 'Ядам',
            'title_cyrillic'         : 'Монгол улсын гавъяат эдийн засагч, ТМЗ',
            'displayName_latin'   : 'Batjargal Yadam',
            'firstName_latin'     : 'Batjargal',
            'lastName_latin'      : 'Yadam',
            'title_latin'         : 'Honored economist, Certified tax consultant',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/52ed9980-e6c8-49c9-b299-9f869ebd5ba2.png?updatedAt=1694527529827',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Зөвлөгч', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '39ce6cb4-d53a-42b7-978f-ddf93ae180fd',
            'displayName_cyrillic'   : 'Г.Цэцгээ',
            'firstName_cyrillic'     : 'Цэцгээ',
            'lastName_cyrillic'      : 'Г',
            'title_cyrillic'         : 'Зөвлөх',
            'displayName_latin'   : 'Tsetsegee G',
            'firstName_latin'     : 'Tsetsegee',
            'lastName_latin'      : 'G',
            'title_latin'         : 'Consultant',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/39ce6cb4-d53a-42b7-978f-ddf93ae180fd.png?updatedAt=1694527528785',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Зөвлөгч', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '6c8ab097-2dc8-4074-a620-e56a994931a0',
            'displayName_cyrillic'   : '-',
            'firstName_cyrillic'     : '-',
            'lastName_cyrillic'      : '-',
            'title_cyrillic'         : '-',
            'displayName_latin'   : '-',
            'firstName_latin'     : '-',
            'lastName_latin'      : '-',
            'title_latin'         : 'Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/avatar-default.jpg?updatedAt=1698784931222',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Салбар', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '6c8ab097-2dc8-4074-a620-e56a994941a0',
            'displayName_cyrillic'   : '-',
            'firstName_cyrillic'     : '-',
            'lastName_cyrillic'      : '-',
            'title_cyrillic'         : '-',
            'displayName_latin'   : '-',
            'firstName_latin'     : '-',
            'lastName_latin'      : '-',
            'title_latin'         : 'Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/avatar-default.jpg?updatedAt=1698784931222',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Салбар', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '6c8ab097-2dc8-4074-a620-e56a994971a0',
            'displayName_cyrillic'   : '-',
            'firstName_cyrillic'     : '-',
            'lastName_cyrillic'      : '-',
            'title_cyrillic'         : '-',
            'displayName_latin'   : '-',
            'firstName_latin'     : '-',
            'lastName_latin'      : '-',
            'title_latin'         : 'Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/avatar-default.jpg?updatedAt=1698784931222',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Салбар', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
        {
            'consultant_id' : '6c8ab097-2dc8-4074-a620-e56a994911a0',
            'displayName_cyrillic'   : 'Г.Золзаяа',
            'firstName_cyrillic'     : 'Золзаяа',
            'lastName_cyrillic'      : 'Г',
            'title_cyrillic'         : 'Өмгөөлөгч',
            'displayName_latin'   : 'Zolzaya G',
            'firstName_latin'     : 'Zolzaya',
            'lastName_latin'      : 'G',
            'title_latin'         : 'Advocate',
            'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/6c8ab097-2dc8-4074-a620-e56a994911a0.png?updatedAt=1694527529574',

            'role'                : 'advisor',
            'role_title_cyrillic' : 'Зөвлөгч', 
            'role_title_latin'    : 'Advisor',
            'role_color'          : '#1b3b48'
        },
    ]
}

export const company_list = [
	{
		'id'    : '1',
		'title' : {
            'en' : 'Legal consulting services',
            'mn' : 'Хууль зүйн зөвлөх үйлчилгээ',
            'cn' : '法律咨询服务'
        },
		'desc'  : 'Finance Business Legal Consensus LLP',
		'comps' : [
			{
				'company_id' : '903ef8f9-67a2-4d14-a773-6918fad1d3c1',
				'name'       : 'FBLC',
				'logo'       : 'https://ik.imagekit.io/lltmixv8i/Company/FBLC/fblc.jpg?updatedAt=1694856922322'
			}
		]
	},
	{
		'id'    : '2',
		'title' : {
            'en' : 'Research and study',
            'mn' : 'Эрдэм шинжилгээ, судалгаа',
            'cn' : '研究与学习'
        },
        'desc'  : '“САНХҮҮ, БИЗНЕСИЙН ЭРХ ЗҮЙН СУДАЛГААНЫ ХҮРЭЭЛЭН” ТББ',
		'comps' : [
			{
				'company_id' : '332caf35-9418-43e1-885d-0e0c7a9798a0',
				'name'       : 'TBB',
				'logo'       : 'https://ik.imagekit.io/lltmixv8i/Company/TBB/TBB.jpg?updatedAt=1694856896269'	
			}
		]
	},
	{
		'id'    : '3',
		'title' : {
            'en' : 'Law Firm',
            'mn' : 'Хуулийн Фирм',
            'cn' : '人力资源咨询服务'
        },
		'desc'  : 'DHH Mongolia',
		'comps' : [
			{
				'company_id' : '56590b30-64d8-4326-b43f-ed8c685c5133',
				'name'       : 'Mongolia DHH Law',
                "logo_static": "dhh_logo_mini.jpg",
				// 'logo'       : "http://en.deheheng.com/images/en_logo.jpg",
                
			}
		]
	}
	// {
	// 	'id'    : '3',
	// 	'title' : {
    //         'en' : 'Human resource consulting services',
    //         'mn' : 'Хүний нөөцийн зөвлөх үйлчилгээ',
    //         'cn' : '人力资源咨询服务'
    //     },
	// 	'desc'  : 'iJob LLC',
	// 	'comps' : [
	// 		{
	// 			'company_id' : '0bde2489-007c-40f9-8be5-9472a9c196d3',
	// 			'name'       : 'iJob',
	// 			'logo'       : "https://ik.imagekit.io/lltmixv8i/Company/iJOB/iJob.png?updatedAt=1703628160324",
	// 		}
	// 	]
	// }
]

export const company_fblc = {
    'company_id'  : '903ef8f9-67a2-4d14-a773-6918fad1d3c1',
    'name_en'     : 'Finance Business Legal Consensus LLP',
    'name_mn'     : '"ЭФ БИ ЭЛ СИ" ХХК',
    'description' : {
        'main' : "Өмгөөллийн “FBLC” хуулийн нөхөрлөл 2018 онд үүсгэн байгуулагдаж компанийн зөвлөх үйлчилгээ, өмгөөлөл, иргэн, захиргаа, эрүүгийн эрх зүй, оюуны өмч болон аж ахуйн нэгж, иргэдийн авлага барагдуулах чиглэлээр мэргэшин үйл ажиллагаа явуулж байна. \n\nМанай мэргэжлийн хамт олон зөвлөх үйлчилгээний алба, өмгөөллийн алба, авлага барагдуулалтын алба, Шүүхийн шийдвэр гүйцэтгэлийн алба нэгжүүдээрээ дамжуулан хууль зүйн зөвлөх үйлчилгээг явуулж байна.",
        'sub'  : "Бид үүрэн холбоо, шатахуун нефтийн бүтээгдэхүүн, ББСБ, олон улсын тээвэр зууч, хүнс, эмнэлэг, газар тариалангийн, газар, үл хөдлөх эд хөрөнгө, сууц өмчлөгчдийн эрх зэрэг олон салбарын харилцагч үйлчлүүлэгчидтэй хамтран ажиллаж байна.  Бид хувь хүн, хуулийн этгээдүүдийн оршин суугаа болон үйл ажиллагаа явуулж байгаа хаяг тодорхойгүй, холбоо барих дугаар өөрчлөгдсөн, хөөн хэлэлцэх хугацаа дууссан, найдваргүй авлагуудыг барагдуулж үйлчлүүлэгчдийн хохирлыг барагдуулан ажиллаж өнгөрсөн хугацаанд давхардсан тоогоор нийт 7.795.968.000 төгрөгийн авлага барагдуулж, үйлчлүүлэгч харилцагчийн хууль ёсны эрх ашгийг хамгаалж ажилласан.\n\nМанай хуулийн фирм “Үндэсний зөвлөгч” зөвлөх үйлчилгээний нэг хэсэг бөгөөд “Санхүү бизнесийн эрхзүйн судалгааны хүрээлэн”, “Санхүүгийн зах зээлийн үндэсний зөвлөл”, “Ай Жоб” хүний нөөцийн зуучлалын компаниудтайгаар үйл ажиллагаа явуулж байна"
    },
    'company_logo' : "https://ik.imagekit.io/lltmixv8i/Company/FBLC/fblc.jpg?updatedAt=1694856922322",
    'people_img' : 'https://ik.imagekit.io/lltmixv8i/Company/FBLC/fblc_people.png?updatedAt=1694856941949', 
    "content" : [
        {
            "order" : 1,
            "type"  : "consultant",
            "title" : "Манай зөвлөхүүд",
            'content' : [
                {
                    'consultant_id' : '70c84fc2-7945-4dc8-8495-d8c736820977',
                    'displayName_cyrillic'   : 'Б.Батзориг',
                    'firstName_cyrillic'     : 'Батзориг',
                    'lastName_cyrillic'      : 'Бадам',
                    'title_cyrillic'         : 'Хууль зүйн ухааны доктор, дэд профессор',
                    'displayName_latin'   : 'Batzorig Badam',
                    'firstName_latin'     : 'Batzorig',
                    'lastName_latin'      : 'Badam',
                    'title_latin'         : 'Doctor of Law, Associate Professor',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/70c84fc2-7945-4dc8-8495-d8c736820977.png?updatedAt=1694527529670'
                },
                {
                    'consultant_id' : '55ac6247-a807-46aa-a955-4dda3cba83b2',
                    'displayName_cyrillic'   : 'О.Цэнгэлбаатар',
                    'firstName_cyrillic'     : 'Цэнгэлбаатар',
                    'lastName_cyrillic'      : 'О',
                    'title_cyrillic'         : 'Стратегийн зөвлөх',
                    'displayName_latin'   : 'Tsengelbaatar O',
                    'firstName_latin'     : 'Tsengelbaatar',
                    'lastName_latin'      : 'O',
                    'title_latin'         : 'Strategy consultant',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/55ac6247-a807-46aa-a955-4dda3cba83b2.png?updatedAt=1694527529622'
                },
                {
                    'consultant_id' : '52ed9980-e6c8-49c9-b299-9f869ebd5ba2',
                    'displayName_cyrillic'   : 'Я.Батжаргал',
                    'firstName_cyrillic'     : 'Батжаргал',
                    'lastName_cyrillic'      : 'Ядам',
                    'title_cyrillic'         : 'Монгол улсын гавъяат эдийн засагч, ТМЗ',
                    'displayName_latin'   : 'Batjargal Yadam',
                    'firstName_latin'     : 'Batjargal',
                    'lastName_latin'      : 'Yadam',
                    'title_latin'         : 'Honored economist, Certified tax consultant',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/52ed9980-e6c8-49c9-b299-9f869ebd5ba2.png?updatedAt=1694527529827'
                },
                {
                    'consultant_id' : '43019e09-db35-45ec-98e0-76ff7ff08144',
                    'displayName_cyrillic'   : 'Б.Тунгалагнаран',
                    'firstName_cyrillic'     : 'Тунгалагнаран',
                    'lastName_cyrillic'      : 'Баяраа',
                    'title_cyrillic'         : 'Гүйцэтгэх захирал, Өмгөөлөгч',
                    'displayName_latin'   : 'Tungalagnaran Bayaraa',
                    'firstName_latin'     : 'Tungalagnaran',
                    'lastName_latin'      : 'Bayaraa',
                    'title_latin'         : 'CEO, Advocate',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/43019e09-db35-45ec-98e0-76ff7ff08144.png?updatedAt=1694527531378'
                },
                {
                    'consultant_id' : '39ce6cb4-d53a-42b7-978f-ddf93ae180fd',
                    'displayName_cyrillic'   : 'Г.Цэцгээ',
                    'firstName_cyrillic'     : 'Цэцгээ',
                    'lastName_cyrillic'      : 'Г',
                    'title_cyrillic'         : 'Зөвлөх',
                    'displayName_latin'   : 'Tsetsegee G',
                    'firstName_latin'     : 'Tsetsegee',
                    'lastName_latin'      : 'G',
                    'title_latin'         : 'Consultant',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/39ce6cb4-d53a-42b7-978f-ddf93ae180fd.png?updatedAt=1694527528785'
                },
                {
                    'consultant_id' : '9e8350f7-5e8b-44e6-95e7-156069321c58',
                    'displayName_cyrillic'   : 'М.Энхжаргал',
                    'firstName_cyrillic'     : 'Энхжаргал',
                    'lastName_cyrillic'      : 'Махбал',
                    'title_cyrillic'         : 'Өмгөөлөгч',
                    'displayName_latin'   : 'Enkhjargal Makhbal',
                    'firstName_latin'     : 'Enkhjargal',
                    'lastName_latin'      : 'Makhbal',
                    'title_latin'         : 'Advocate',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/9e8350f7-5e8b-44e6-95e7-156069321c58.png?updatedAt=1694527529596'
                },
                {
                    'consultant_id' : '6c8ab097-2dc8-4074-a620-e56a994911a0',
                    'displayName_cyrillic'   : 'Г.Золзаяа',
                    'firstName_cyrillic'     : 'Золзаяа',
                    'lastName_cyrillic'      : 'Г',
                    'title_cyrillic'         : 'Өмгөөлөгч',
                    'displayName_latin'   : 'Zolzaya G',
                    'firstName_latin'     : 'Zolzaya',
                    'lastName_latin'      : 'G',
                    'title_latin'         : 'Advocate',
                    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/6c8ab097-2dc8-4074-a620-e56a994911a0.png?updatedAt=1694527529574'
                },
            ]
        },
        {
            "order" : 2,
            "type"  : "list",
            "title" : "Бидний мэргэшсэн чиглэлүүд",
            "content" : [
                "Бүх шатны Эрүү, Иргэн Захиргааны шүүх, эвлэрүүлэн зуучлах ажиллагаанд төлөөлөх, өмгөөлөх",
                "Авлага барагдуулах",
                "Шүүхийн шийдвэр гүйцэтгэх ажиллагаанд төлөөлөх",
                "Татварын зөвлөх үйлчилгээ",
                "Оюуны өмчийн бүртгэл, маргаан",
                "Хүнсний салбарын зөвлөх үйлчилгээ",
                "Эмнэлгийн салбарын зөвлөх үйлчилгээ ",
                "Гэр бүлийн маргаанд төлөөлөх",
                "Хөдөлмөрийн харилцаа, сургалт",
                "Үнэт цаас, хөрөнгийн зах зээлийн зөвлөх үйлчилгээ",
                "Компанийн засаглал",
                "Хүний нөөцийн зөвлөх, зуучлал",
                "Комплаенс, сургал"
            ],
        },
        {
            "order" : 3,
            "type"  : "contact",
            "title" : "Холбоо барих",
            'content' : {
                'mobile' : '(+976) 89115075',
                'email'  : 'undesniizovlogch@gmail.com',
                'address' : 'Улаанбаатар хот, Сүхбаатар дүүрэг, 9-хороо, Ногоон нуурын гудамж, SPS building оффис, 501 тоот'
            },
        },
    ],
}

export const company_tbb = {
    "company_id"  : "3a6c60e4-d78a-4942-9878-e1d1047a19d0",
    "name_en"     : "",
    "name_mn"     : '“САНХҮҮ, БИЗНЕСИЙН ЭРХ ЗҮЙН СУДАЛГААНЫ ХҮРЭЭЛЭН” ТББ',
    "description" : {
        'main' : "'Санхүү, бизнесийн эрх зүйн судалгааны хүрээлэн' ТББ нь эдийн засгийн салбарт хууль, эрх зүйн мэдлэгийг түгээх, хуульчидад эдийн засаг, санхүү бизнесийн тухай ойлголтыг гүнзгийрүүлэх, сурталчлах, сургах, дээрх салбаруудын хөгжилд хувь нэмрээ оруулах зорилгын хүрээнд үүсгэн байгуулагдсан Эрдэмтэн судлаачдаас бүрдсэн эрдмийн зөвлөл, салбар бүрийг хариуцан хөгжүүлэх хороодтойгоор үйл ажиллагаа явуулж буй Төрийн Бус Байгууллага юм.",
        'sub'  : "Дээрх зорилгын хүрээнд явуулж буй үйл ажиллагаа: \n - Сургалт, судалгааны ажил хийх \n - Ном товхимол хэвлэн нийтлэх \n - Эрх зүйн актуудыг боловсронгуй болгоход мэргэжлийн холбоодтой хамтран санал өгөх"
    },
    "company_logo" : "https://ik.imagekit.io/lltmixv8i/Company/TBB/TBB.jpg?updatedAt=1694856896269",
    "people_img" : "", 
    "content" : [
        {
            "order" : 1,
            "type"  : "text",
            "title" : "МАНАЙ БҮРЭЛДЭХҮҮН",
            "content" : "Тус Төрийн бус байгууллагын удирдах зөвлөлийн гишүүд нь хууль эрх зүйн салбар, банк бус санхүүгийн байгууллага, татвар, даатгал, үнэт цаас, оюуны өмчийн салбараар мэргэшсэн шинжлэх ухааны доктор, профессор, эрдэмтэн судлаачдаас бүрддэг бөгөөд шинэ залуу судлаачдын багтайгаар үйл ажиллагаагаа явуулж байна.",
        },
        {
            "order" : 2,
            "type"  : "text",
            "title" : "ЗӨВЛӨХҮҮД",
            "content" : "Хууль зүйн ухааны доктор, профессор Б.Батзориг, МХХ-ны Эрүүгийн эрх зүйн хорооны тэргүүн, Монголын Криминологичдын холбооны УЗ-н гишүүн, судлаач Г.Оюунболд, Эрүүгийн процессын эрх зүй, докторант Б.Эрдэнэбилэг, Оюуны өмчийн итгэмжлэгдсэн төлөөлөгч, хуульч Б.Батцэнгэл нар ажиллаж байна.",
        },
        {
            "order" : 3,
            "type"  : "text",
            "title" : "ХАМТРАН АЖИЛЛАГЧ БАЙГУУЛЛАГУУД:",
            "content" : "Бид үйл ажиллагаа явуулахдаа салбар бүрт нээлттэй, хамтран ажиллах зарчмыг баримталдаг бөгөөд Монголын Үндэсний Худалдаа Аж Үйлдвэрийн Танхим, Комплаенсийн Үндэсний Зөвлөл, Монголын Хүнсчидийн Холбоо, Монголын Банк БусСанхүүгийн байгууллагын Холбоо, Өмгөөллийн “Эф Би Эл Си” ХХН, Монголын Банкнуудын Холбоо, Санхүүгийн комплаенсийн Холбоо зэрэг олон байгууллагуудтай хамтран ажиллаж байна.",
        },
        {
            "order" : 4,
            "type"  : "list",
            "title" : "БИДНИЙ ҮЙЛ АЖИЛЛАГАА",
            "content" : [
                "Банк, банк бус санхүүгийн байгууллага, санхүү, төсөв, бизнес, татвар, даатгал, хөрөнгийн зах зээл, хүнс, хөдөө аж ахуй, барилга, уул уурхайн салбарынханд үйл ажиллагаа явуулахад учирч буй хүндрэл бэрхшээлийг давахад нь туслах, чадавхжуулах зорилгоор сургалт, зөвлөгөө өгөх",
                "Дээрх салбарт ач холбогдол бүхий асуудлаар судалгаа хийх",
                "Дээрх салбарт суралцаж байгаа оюутнуудад практикт суурилсан мэдлэг олгох, мэргэшүүлэх",
                "Салбарыг хамарсан эрдэм шинжилгээний хурал зохиох, төсөл хөтөлбөр хэрэгжүүлэх"
            ]
        },
        {
            "order" : 5,
            "type"  : "list",
            "title" : "ХИЙЖ ГҮЙЦЭТГЭСЭН АЖИЛ",
            "content" : [
                "“ТЭСО КОРПОРАЦИ” ХХК-ний “МИЛКО”ХХК-ний ажилтан албан хаагчдад зориулсан багц сургалт -2018 он",
                "Санхүүгийн Зохицуулах Хорооны захиалгаар “Улсын байцаагч Зөрчлийн хуулийн дагуу торгууль оногдуулсан шийдвэрт дүн шинжилгээ хийх”, “Хорооны шийдвэр, үйл ажиллагаатай холбоотой шүүхийн шийдвэрт дүн шинжилгээ” судалгаа – 2019 он",
                "“Зөрчлийн тухай хуульд дүн шинжилгээ, судалгаа” -2019 он",
                "Даатгалын салбарын эрх зүйн актын эмхтгэл -2020 он",
                "Нээлттэй Нийгэм Форумаас зарласан “Эрүүгийн хуулийн 22.1 дэх хэсэгт заасан гэмт хэргийн шүүхийн шийдвэрт анализ хийх” судалгаа -2020 он",
                "ЖҮХ-ны захиалгаар MMCG ХХК-тай хамтран “Жендэрийн эрх тэгш байдлыг хангах тухай хуулийн хэрэгжилт” судалгаа (2019 он)",
                "Төрийн албаны зөвлөлийн захиалгат “Төрийн албан хаагчийн ёс зүйн хэмжээний хэрэгжилт” судалгаа (2020 он)- г",
                "АТГ, Хөгжлийн шийдэл ТББ-н захиалгат ХХААХҮ-н салбарын ЖДБЭ-чдэд төрийн үйлчилгээ үзүүлдэг төрийн албан хаагчдад зориулсан Ил тод байдлын гарын авлага боловсруулах - (2021 он) тус тус хийж хүлээлгэн өгөөд байна.",
                "Төрийн албаны зөвлөлийн захиалгат “Төрийн албаны тухай хуулийн хэрэгжилтийг эрчимжүүлэх бодлогын зөвлөмж боловсруулах” 2022",
            ],
        },
        {
            "order" : 6,
            "type"  : "text",
            "title" : "",
            "content" : "Дээр дурдагдсан ажлаас гадна МҮХАҮТ-тай хамтран “Комплаенсийн Үндэсний Зөвлөл”-г байгуулан сургалт, сурталчилгаа, эрдэм шинжилгээний хурал, комплаенсийг үйл ажиллагаандаа нэвтрүүлэхийг хүссэн аж ахуйн нэгж байгууллагуудад зөвлөгөө, туслалцаа үзүүлэн ажиллаж байна.",
        },
    ],
}

export const company_ijob = {
    "company_id"  : "cb052934-a23d-4869-bc67-6e4cd444bb4b",
    "name_en"     : "iJob",
    "name_mn"     : '“АЙ ЖОБ” ХХК',
    "description" : {
        'main' : "“Ай Жоб” ХХК нь 2022 онд Төсөл хөтөлбөр хэрэгжүүлэх, Бизнесийн зөвлөгөө өгөх, Дотоодод хөдөлмөр зуучлах гэсэн чиглэлээр үүсгэн байгуулагдаж үйл ажиллагаагаа явуулж байна.",
        'sub'  : "Бид хөдөлмөрийн зах зээл, хүний нөөцийн салбарт өөрийн хувь нэмрээ оруулахаар ажил олгогчийн шаардлагад нийцсэн өндөр ур чадвартай ажилтныг тодорхойлох, тэдгээрийг ажилд цахимаар зуучлах чиглэлээр үйл ажиллагаа явуулж байна."
    },
    "company_logo" : "https://ik.imagekit.io/lltmixv8i/Company/iJOB/iJob.png?updatedAt=1703628160324",
    "people_img" : "", 
    "content" : [
        {
            "order" : 1,
            "type"  : "text",
            "title" : "Эрхэм зорилго",
            "content" : "“Бид таны ур чадвар, үнэт зүйлсийг тодорхойлж, хүсэл мөрөөдөлд нийцсэн ажлын байранд хүргэнэ.”",
        },
        {
            "order" : 2,
            "type"  : "text",
            "title" : "Алсын хараа",
            "content" : "Бид хөдөлмөрийн зах зээлд/хүний нөөцийн салбарт/ эрэлт хэрэгцээг хангасан, мэдлэг, технологит суурилсан үндэсний тэргүүлэгч компани болно.",
        },
        {
            "order" : 3,
            "type"  : "list",
            "title" : "Бидний үнэт зүйлс",
            "content" : [
                "Хүний нөөцийн чиглэлээр мэргэшсэн",
                "Нууцлал, Аюулгүй ажиллагаа",
                "Чадварлаг баг хамт олон",
                "Чанартай үйлчилгээ",
                "Өндөр хариуцлага",
                "Харилцагч байгууллагуудын итгэл даасан"
            ],
        },
        {
            "order" : 4,
            "type"  : "list",
            "title" : "Манай хамт олон ",
            "content" : [
                "ажил хайгч болон ажил олгогчийн үйл ажиллагааг хөнгөвчлөх, ",
                "тэдний мэдээлэл солилцох хурдыг нэмэгдүүлэх, ",
                "үйлчлүүлэгчийн талаарх мэдээллийг найдвартай эх сурвалжаас авч ажилтны үнэлэмжийг нэмэгдүүлэх, ",
                "хэрэгцээ шаардлагад нийцсэн ажилтан, ажлын байрны талаарх мэдээллийг олборлон баталгаажуулж, гишүүдэд түгээх, ",
                "мэргэжлээрээ өндөр түвшинд бэлтгэгдсэн, туршлагатай чадварлаг ажилтан бэлдэх, тэдний дасгалжуулах зөвлөх, үнэлэмжийг нэмэгдүүлэхэд чиглэсэн  сургалт өгөх зэрэг үйлчилгээг үзүүлэх болно.",
            ]
        }
    ],
}

export const company_dhh = {
    "company_id"  : "56590b30-64d8-4326-b43f-ed8c685c5133",
    "name_en"     : "",
    "name_mn"     : 'Хуулийн ферм ',
    "description" : {
        'main' : "“Beijing DHH хуулийн фирм болон Өмгөөллийн “Эф Би Эл Си” ХХН нарын хооронд байгуулагдсан Хамтран ажиллах гэрээний дагуу бид Монгол Улсад түр болон байнга оршин суух БНХАУ-ын иргэд, бизнес эрхлэгч иргэн, аж ахуйн нэгж байгууллага болон хөрөнгө оруулагч нарын эрх, хууль ёсны ашиг сонирхлыг хамгаалах хууль зүйн менежментийн цогц зөвлөх үйлчилгээ үзүүлэх зорилготойгоор “DHH Law Mongolia” Limited Liability Company албан ёсоор үйл ажиллагаа явуулж эхэлсэн.",
        'sub'  : "Тус компани нь БНХАУ-ын иргэд, бизнес эрхлэгчид олноор ажиллаж, амтьдардаг 12 аймагт салбар, салбар төлөөлөгчтэй. Цаашид бизнесийн тодорхой салбарынханд хууль зүйн цогц үйлчилгээ үзүүлэх бизнес төвүүд байгуулан ажиллахаар төлөвлөж байна. DHH Law Mongolia компанийн гол зорилго нь ёс зүй, тогтвортой байдал, шударга ёс. Бид Монгол Улсын болон олон улсын жишигт нийцсэн хууль зүйн зөвлөх үйлчилгээг үзүүлэхийг ямагт эрхэмлэнэ."
    },
    // "company_logo" : "http://en.deheheng.com/images/en_logo.jpg",
    "company_logo_static": "dhh_logo_tsenher.jpg",
    "people_img" : "", 
    "content" : [
        {
            "order" : 1,
            "type"  : "list",
            "title" : "Бидний тухай",
            "content" : [
                "Мэргэжлийн, туршлагатай чадварлаг хамт олон;",
                "Салбартаа тэргүүлэгч зөвлөх мэргэжилтнүүд;",
                "Бизнес мэдлэг;",
                "Хууль, эрх зүйн мэргэжлийн үйлчилгээ;",
                "Иргэний, эрүүгийн захиргааны шүүх, олон улсын арбитрт төлөөлөх;",
                "12 аймагт салбартай."
            ],
        },
        {
            "order" : 2,
            "type"  : "text",
            "title" : "Бидний үйл ажиллагаа",
            "content" : "Бид уул уурхай, дэд бүтэц, автозамын бүтээн байгуулалт, газрын харилцаа, банк, санхүүгийн зэрэг Монгол Улсад хэрэгжүүлэх мега төсөл хөтөлбөрт хууль зүйн дүн шинжилгээ хийх, зөвлөх үйлчилгээ үзүүлэх; Санхүүгийн салбарт хөрөнгө оруулагч нарт мэргэшсэн зөвлөхүүд зөвлөх үйлчилгээ үзүүлэх; IPO гаргах болон хөрөнгө оруулалтын гэрээ хэлэлцээрт зөвлөх, бүх төрлийн гэрээний төсөл боловсруулах, гэрээний хэрэгжилт түүний дагуу үүсэх гэрээний үр дагаварт хууль зүйн дүгнэлт, зөвлөмж өгөх, Комплаенс хууль тогтоожид нийцүүлэх, татвар, гааль, улсын бүртгэлийн иж бүрэн үйлчилгээ үзүүлэх, Шүүх, хууль хяналтын байгууллагад болон Олон улсын арбитрт бүрэн төлөөлөх /Иргэн, эрүү, захиргааны хэргийн шүүхэд төлөөлөх, хууль зүйн туслалцаа үзүүлэх, оюуны өмч болон бусад зөрчлийн хэрэг маргаан, хөдөлмөрийн, гэр бүлийн маргааны эвлэрүүлэн зуучлалд төлөөлөх гэх мэт/; Авлага барагдуулах /нотариатын мэдэгдэх хуудас, төлбөр барагдуулах гэрээ, шүүхийн дэргэдэх эвлэрүүлэн зуучлал/ гэх мэт зөвлөх бусад үйлчилгээ үзүүлнэ.",
        },
        {
            "order" : 3,
            "type"  : "text",
            "title" : "BEIJING DHH LAW FIRM",
            "content" : "Beijing DHH хуулийн фирм （“DHH”）нь 2010 онд үүсгэн байгуулагдсан мэргэшил, стандартчилал, даяаршил, үйлчлүүлэгчийн сэтгэл ханамжийг хангах чиглэлээр ажилладаг хуулийн фирм юм. Бээжин хотод төвтэй, DHH нь олон улсад 30 гаруй оффистой, 2000 гаруй ажилтантай.",
        },
        {
            "order" : 4,
            "type"  : "list",
            "title" : "",
            "content" : [
                "2000+ хуульч өмгөөлөгч;",
                "Олон улсад 12 салбартай;",
                "30+ оффис;",
                "Олон улсын 20000+ хуульч;",
                "Компанид зориулсан эрх зүйн цогц үйлчилгээ;",
                "Бизнес мэдлэг;",
                "19 дадлагын төв;",
                "80+ мэргэжлийн байгууллага."
            ]
        },
        {
            "order" : 5,
            "type"  : "text",
            "title" : "",
            "content" : `DHH нь үйлчлүүлэгчдийнхээ зорилго, хүсэл эрмэлзэлд анхаарлаа төвлөрүүлж "Мэргэжлийн байдал, Төвлөрөл, Мэргэшсэн байдал, Хичээл зүтгэл" үйлчилгээний философийг хэрэгжүүлэн ажилладаг.`,
        },
        {
            "order" : 6,
            "type"  : "text",
            "title" : "",
            "content" : `DHH нь мэргэжлийн ур чадвар, багаар ажиллах чадвар, нэр хүнд, өндөр чанартай үйлчилгээнийхээ ачаар харилцагчийн итгэл даахуйц иж бүрэн үйлчилгээг үзүүлдэг хамгийн том бүрэн хэмжээний худалдааны хуулийн фирмүүд нэг болсноороо бахархдаг.`,
        },
        {
            "order" : 7,
            "type"  : "text",
            "title" : "",
            "content" : `Хятад дахь хууль зүйн үйлчилгээний даяаршлын андагчдын нэг болох DHH нь Хойд Америк, Орос, Зүүн Ази, Зүүн өмнөд Ази болон бусад бүс нутагт албан ёсны салбартай бөгөөд гадаадад шууд эзэмшдэг арван хоёр салбар, төлөөлөгчийн газартай.`,
        },
        {
            "order" : 8,
            "type"  : "contact",
            "title" : "Холбоо барих",
            'content' : {
                'mobile' : '+86-10-85407666 / 85534166',
                'fax': '+86-10-85407608',
                'email'  : 'beijing@deheheng.com',
                'address' : '11/F and 12/F, Tower C, Beijing Yintai Centre, No.2 Jianguomenwai Avenue, Chaoyang District, Beijing, P.R. China'
            },
        },
    ],
}


export const company_dhh_en = {
    "company_id"  : "56590b30-64d8-4326-b43f-ed8c685c5133",
    "name_en"     : "Mongolia DHH Law",
    "name_mn"     : '',
    "description" : {
        'main' : "“DHH Law Mongolia” LLC law firm was officially established according to Cooperation Agreement executed by the Beijing DHH law firm and “FBLC” LLP, in order to protect right and legal interests of citizens residing temporarily and permanently, citizen and legal entities with business activities and investors in Mongolia from China and to provide complex legal management consulting service for all aspects for sustainable activity according to law.",
        'sub'  : "“DHH Law Mongolia” LLC law firm is cooperating with lawyers, advocates, professional advisors, affiliated companies such as “National Consultant” LLC, “FBLC” LLP, Research Institute of Financial, Business Law NGO, researchers, local branches and branch offices. Main purpose of our law company is to adore ethic, sustainable and justice and we established branches in 12 provinces of Mongolia that numerous citizen and entity of China are living and residing. Also we established specialized center for construction field which field is the most invested in Mongolia from PRC."
    },
    // "company_logo" : "http://en.deheheng.com/images/en_logo.jpg",
    "company_logo_static": "dhh_logo_tsenher.jpg",
    "people_img" : "", 
    "content" : [
        {
            "order" : 11,
            "type"  : "list",
            "title" : "About us",
            "content" : [
                "Our team is professional organization with a competent and team of lawyers with expertise in all areas of theoretical and practical law;",
                "Industry-leading consulting experts;",
                "Business Savvy;",
                "Professional premium and effective legal services to all clients;",
                "Represent in civil, criminal, administration court and international arbitration;",
                "12 branches in the provincial area."
            ],
        },
        {
            "order" : 12,
            "type"  : "text",
            "title" : "WHAT WE DO?",
            "content" : "We are rendering comprehensive legal management service such as consulting service and relations of land, legal assessment on mega projects performing within territory of Mongolia as railway, construction, mining, infrastructure and construction of road, banking and finance fields, consulting service by specialized experts in tax and finance for investors, consulting on IPO issuance and investment agreement, drafting all type of contract, provide legal conclusion and recommendation on outcome of performance of the contract, legal aid service in International arbitration(represent in civil, criminal, administration court, legal aid service, represent in intellectual property litigation/dispute and other litigation and mediation of family disputes etc.), Debt settlement (notary notification, indeed agreement, court assigned mediation) and other, to citizen, legal entities and investors from China, based on their interests and its business activities industrial specific Advantage is that we are giving opportunity to choose from lawyer, advocates, professional advisors, and experts who are specialized in their fields, based on interest of entrepreneurs, its business activities, field specific and legal condition.",
        },
        {
            "order" : 13,
            "type"  : "text",
            "title" : "BEIJING DHH LAW FIRM",
            "content" : "Founded in 2010, Beijing DHH Law Firm( “DHH”) is a law firm dedicated to specialization, standardization, globalization, and client satisfaction. Headquartered in Beijing, DHH has 2,000 practitioners in more than 30 offices located in China and numerous investment and trade hotspots overseas.",
        },
        {
            "order" : 14,
            "type"  : "list",
            "title" : "",
            "content" : [
                "2000+ Professional Lawyers;",
                "12 Directly invested Overseas Offices;",
                "30+ Offices;",
                "Global Network of 20000+ Lawyers;",
                "Full Service for Listed Companies;",
                "Business Savvy;",
                "19 Practice Centers;",
                "Expertise Across 80+ Professional Aries."
            ]
        },
        {
            "order" : 15,
            "type"  : "text",
            "title" : "",
            "content" : `DHH embraces the service philosophy of "Professionalism, Concentration, Specialization,
and Dedication" focusing on our clients' goals and ambitions. DHH takes pride in specialization
and teamwork and has become one of the largest full-service commercial law firms due to
sound reputation and high-quality client service.`,
        },
        {
            "order" : 16,
            "type"  : "text",
            "title" : "",
            "content" : `As one of the pioneers of legal service globalization in China, DHH has set up twelve
Ministry of Justice certified directly owned overseas branches and representative offices in
North America, Russia, East Asia, South- east Asia, and other regions.`,
        },
    ],
}




export const batzorig = {
    "consultant_id" : "70c84fc2-7945-4dc8-8495-d8c736820977",
    "profile_img" : "https://ik.imagekit.io/lltmixv8i/Consultant/70c84fc2-7945-4dc8-8495-d8c736820977.png?updatedAt=1694527529670",
    
    "familyName_cyrillic" : "Гэгээнтэн",
    "familyName_latin"    : "Gegeenten",
    
    'firstName_latin'     : 'Batzorig',
    'firstName_cyrillic'  : 'Батзориг',
    
    'displayName_cyrillic': 'Б.Батзориг',
    'displayName_latin'   : 'Batzorig Badam',
    
    'lastName_cyrillic'   : 'Бадам',
    'lastName_latin'      : 'Badam',
    
    'title_cyrillic'      : 'Хууль зүйн ухааны доктор, дэд профессор',
    'title_latin'         : 'Doctor of Law, Associate Professor',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "text",
            
            "title_en" : "",
            "title_mn" : "Мэргэжил",

            "content_en" : "",
            "content_mn" : "Эрх зүйч"
        },
        {
            "order" : 2,
            "type"  : "list_desc",
            
            "title_en" : "",
            "title_mn" : "Боловсролын болон эрдмийн зэрэг, цол",
            
            "content_en" : [

            ],
            "content_mn" : [
                {
                    "order" : 1,
                    "heading" : "Хууль зүйн ухааны доктор, дэд профессор",
                    "subheading" : [
                        "Сэдэв: “Эрүүгийн хэргийн нотлох баримт цуглуулах, бэхжүүлэх ажиллагааны онол, практикийн асуудал”",
                    ]
                }
            ]
        },
        {
            "order" : 3,
            "type"  : "text",
            
            "title_en" : "",
            "title_mn" : "Гадаад хэлний мэдлэг",
            
            "content_en" : "",
            "content_mn" : "Орос, англи хэлтэй"
        },
        {
            "order" : 4,
            "type"  : "list",
            
            "title_mn" : "",
            "title_en" : "Суралцсан байдал",

            "content_en" : [

            ],
            "content_mn" : [
                "1994 Цагдаагийн дээд сургууль",
                "1998 МУИС-ийн Хууль зүйн сургууль магистр",
                "2012 МУИС-ийн доктор",
            ]
        },
        {
            "order" : 5,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Ажилласан байдал",

            "content_en" : [

            ],
            "content_mn" : [
                "1993-1994 он. Сүхбаатар дүүргийн Цагдаагийн хэлтсийн мөрдөн байцаагч",
                "1994-1996 он. Хэнтий аймгийн Цагдаагийн хэлтсийн мөрдөн байцаагч",
                "1997 он. Улсын мөрдөн байцаах газрын 6 дугаар хэлтэс мөрдөн байцаагч",
                "1997-2008 он. Цагдаагийн дээд сургууль /Цагдаагийн академи/ багш, ахлах багш",
                "2008-2014 он. Авлигатай тэмцэх газрын албаны дарга",
                "2014-2016 он. Тахарын ерөнхий газрын дэд дарга",
                "2016 он. Хууль зүйн яамны Төрийн захиргааны удирдлагын газрын дарга",
                "2016-2020 он Санхүүгийн зохицуулах хорооны Захиргаа удирдлагын газрын дарга",
                "2014 оноос Хуульчдын холбооны болон Өмгөөлөгчдийн холбооны сургагч багш",
                "2020 оны 4 сараас Монголын Криминологичдын холбооны УЗ-ын дарга, ",
                "2019 оноос Худалдаа аж үйлдвэрийн танхимын дэргэдэх Комплаенсын зөвлөлийн гишүүн бөгөөд зөвлөх",
                "2018 оноос Их Засаг олон улсын Их сургуулийн Эрдмийн зөвлөлийн гишүүн",
                "2020 оноос Хууль сахиулах их сургуулийн профессорын багийн гишүүн",
                "2020 оноос Хууль зүй, дотоод хэргийн яамны дэргэдэх Эрүүгийн болон Эрүүгийн хэрэг хянан шийдвэрлэх тухай хуулийг боловсронгуй болгох, хэрэгжилтийн үр дүнтэй байдлыг хангах ажлын хэсгийн гишүүнээр",
            ]
        },
        {
            "order" : 6,
            "type"  : "text",
            
            "title_en" : "",
            "title_mn" : "Б.Батзоригийн бүтээлийн жагсаалт",
            
            "content_en" : "",
            "content_mn" : "Хууль зүйн сургууийн доктор, дэд профессор Б.Батзориг 2012 онд МУИС-ийн эрдмийн зэрэг хамгаалуулах зөвлөлөөр “Эрүүгийн хэргийн нотлох баримт цуглуулах, бэхжүүлэх ажиллагааны онол, практикийн асуудал” сэдвээр хууль зүйн ухааны докторын зэрэг хамгаалсан."
        },
        {
            "order" : 7,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Хуулийн төсөл боловсруулах ажил",
            
            "content_en" : [

            ],
            "content_mn" : [
                "1. Эрүүгийн хэрэг хянан шийдвэрлэх хуулийн төсөл /Хууль зүйн сайдын тушаалаар/ 2013 он",
                "2. Эрүүгийн хэрэг шалган шийдвэрлэх хуулийн төсөл /Хууль зүйн сайдын тушаалаар/ 2014 он",
                "3. Зөрчил шалган шийдвэрлэх хуулийн төсөл /Хууль зүйн сайдын тушаалаар/ 2014 он",
                "4. Эрүүгийн хэрэг шалган шийдвэрлэх хуулийн төсөл /Хууль зүйн байнгын хорооны даргын шийдвэр/ 2015 он",
                "5. Зөрчлийн тухай хуулийн төсөл /Хууль зүйн байнгын хорооны даргын шийдвэр / 2015 он",
                "6. Зөрчил шалган шийдвэрлэх хуулийн төсөл /Хууль зүйн байнгын хорооны даргын шийдвэр / 2015 он",
                "7. Хууль сахиулах ажиллагааны тухай хуулийн төсөл /Хууль зүйн байнгын хорооны даргын шийдвэр/ 2016 он",
                "8. Эрүүгийн хэрэг хянан шийдвэрлэх тухай хуулийн төсөл /Хууль зүй, дотоод хэргийн сайдын болон Хууль зүйн байнгын хорооны даргын шийдвэр/ 2017 он",
                "9. Зөрчил шалган шийдвэрлэх хуулийн төсөл /Хууль зүй, дотоод хэргийн сайдын болон Хууль зүйн байнгын хорооны даргын шийдвэр/ 2017 он",
                "10. Гэмт хэрэг, зөрчлөөс урьдчилан сэргийлэх тухай хуулийн төсөл Хууль зүйн байнгын хорооны даргын шийдвэр/ 2018",
                "11. Эрүүгийн хууль, Эрүүгийн хэрэг хянан шийдвэрлэх тухай хууль, Зөрчлийн тухай хууль, Зөрчил шалган шийдвэрлэх тухай хууль, Шүүхийн шийдвэр гүйцэтгэх тухай хуульд нэмэлт, өөрчлөлт оруулах ажлын хэсэг /Хууль зүйн байнгын хорооны даргын шийдвэр/ 2019, 2021 он",
                "12. Мөнгө угаах болон терроризмын санхүүжүүлэхтэй тэмцэх тухай хууль нэмэлт, өөрчлөлт оруулах тухай хуульд нэмэлт, өөрчлөлт оруулах ажлын хэсэг /Хууль зүйн байнгын хороо/ 2019 он",
                "13. Санхүүгийн зохицуулах хорооноос боловсруулсан Банк, банкнаас бусад санхүүгийн салбарын үйл ажиллагааг зохицуулах хууль, тогтоомжийн төсөл боловсруулах ажлыг удирдан зохион байгуулах ажлыг хариуцаж байсан. 2016-2020 он",
                "14. Эрүүгийн болон Эрүүгийн хэрэг хянан шийдвэрлэх тухай хуульд нэмэлт, өөрчлөлт оруулах ажлын хэсэг /Хууль зүйн байнгын хороо/ 2022 оны 5 сар",
                "15. Эрүүгийн хуульд нэмэлт, өөрчлөлт оруулах ажлын хэсэг /УИХ-ын даргын шийдвэр/ 2022 оны 12 сар",
            ]
        },
        {
            "order" : 8,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Хуулийн хэрэгжилтэд хийсэн үнэлгээ",
            
            "content_en" : [

            ],
            "content_mn" : [
                "1. Нээлттэй нийгэм форумаас зарлагдаж хэрэгжсэн Эрүүгийн хэрэг хянан шийдвэрлэх тухай хуулийн хэрэгжилтэд мониторингийн үнэлгээний багуудын зөвлөхөөр ажилласан. 2018, 2019",
                "2. Зөрчлийн хуулийн хэрэгжилтэд хийсэн дүн шинжилгээ. УИХ-ын Хууль зүйн байнгын хороо, Нээлттэй нийгэм форум,  2019.11.01-2020.03.01. Судалгааны багийн ахлагч",
                "3. Зөрчил шалган шийдвэрлэх тухай хуулийн хэрэгжилтэд хийсэн дүн шинжилгээ. УИХ-ын Хууль зүйн байнгын хороо, Нээлттэй нийгэм форум,  2019.11.01-2020.03.01. Судалгааны багийн зөвлөх",
                "4. Санхүүгийн зохицуулах хорооноос боловсруулсан Банк, банкнаас бусад санхүүгийн салбарын үйл ажиллагааг зохицуулах хууль, тогтоомжийн хэрэгжилтийг үнэлэх ажлыг удирдан зохион байгуулах ажлыг хариуцаж байсан. 2016-2020 он",
                "5. Жендерийн эрх тэгш байдлыг хангах тухай хуулийн хэрэгжилтийн нөлөө судалгааны ажил. Жендерийн Үндэсний хороо, 2020 он",
            ]
        },
        {
            "order" : 9,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Судалгааны ажил /сүүлийн 3 жилд/",
            
            "content_en" : [

            ],
            "content_mn" : [
                "1. “Үндэсний аюулгүй байдлын эсрэг гэмт хэргийн эрүүгийн эрх зүй болон мөрдөн шалгах ажиллагаа” сургалтын хөтөлбөр, гарын авлага боловсруулах. ТЕГ, 2019 он.",
                "2. “Эрүүгийн хэрэг хянан шийдвэрлэх ажиллагаа дахь хүүхдийн эрхийг хамгаалах нь” мөрдөгч, прокурор, шүүгч нарт зориулсан сургалтын модуль. Нээлттэй нийгэм форум. 2019 он.",
                "3. Мансууруулах эс, сэтгэцэд нөлөөлөх бодисын хууль бус эргэлтээс урьдчилан сэргийлэх тогтолцоо. Нийслэлийн гэмт хэргээс урьдчилан сэргийлэх ажлыг зохицуулах зөвлөл. 2020",
                "4. Монгол улс дахь хүний наймааны шалтгаан, динамик, хүний наймаанд өртөхөд хүргэж буй нийгмийн эмзэг болон тэсвэртэй байдлыг тодорхойлж төр болон иргэний нийгмийн байгууллагуудад чиглэсэн бодлогын зөвлөмж боловсруулах төсөл. НҮБ, Олон улсын шилжилт хөдөлгөөний байгууллагын захиалгаар. 2020",
                "5. “Монгол Улс дахь авлигатай тэмцэх тогтолцоог бэхжүүлэх нь” төсөл авлигын гэмт хэргийн шийдвэрлэлтийн дүн шинжилгээ хий судалгаа. Азийн сан. 2020",
                "6. “Хүүхдийн эрхийн хууль зүйн хорооны журам”-ын төсөл боловсруулах. НХХЯам. 2021 он",
                "7. Авлигын гэмт хэргийг хянан шийдвэрлэсэн Улсын дээд шүүхийн шийдвэрийн дүн шинжилгээ. Нээлттэй нийгэм форум. 2021 он.",
                "8. Төрийн албаны тухай хуулийн 46 дугаар зүйлийн 46.2.4, 46.2.5, 47 дугаар зүйлийн 47.1.4 дэх заалтад заасан үндэслэлээр төрийн захиргааны албан хаагчдыг төрийн албанаас чөлөөлсөн, халсан тохиолдлыг судалж, хууль тогтоомжийн хэрэгжилтэд дүн шинжилгээ хийх, хэрэгжилтийг эрчимжүүлэх бодлогын зөвлөмж боловсруулах. UNDP. 2022 он",
                "9. “Монгол Улсад хүүхдийн хууль зүйн туслалцаа авах хүртээмжийг нэмэгдүүлэх замаар  Ковид-19 цар тахлын үр нөлөөг бууруулах нь төсөл” Хүүхдийн эрхийн хууль зүйн хорооны гишүүдийг чадавхжуулах сургалт. IDLO, 2022 он.",
                "10. Прокурорын шийдвэр боловсруулах аргачлал. УЕП, Азийн сан. 2022 он.",
                "11. Баян-Өлгий аймагт үйлдэгдсэн гэмт хэрэг, зөрчилд хийсэн криминологийн судалгаа. 2022 он.",
                "12. Шинээр томилогдсон шүүгч, прокурор, өмгөөлөгч нарт зориулсан сургалтын модуль бэлтгэх. УДШ, Азийн сан. 2023",
            ]
        },
        {
            "order" : 10,
            "type"  : "text",
            
            "title_en" : "",
            "title_mn" : "Толь бичиг",

            "content_en" : "",
            "content_mn" : "Хамтран зохиогч, Encyclopedia of Mongolian Studies science of law, /Хятад хэлээр/ 2007."
        },
        {
            "order" : 11,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Сургалтын гарын авлага",
            
            "content_en" : [

            ],
            "content_mn" : [
                "1. “Криминологийн хичээлийг сурах аргачилсан зөвлөмж” 1998 он. ",
                "2. “Оюутан, сонсогчид эрдэм шинжилгээний ажил хийх аргачлал” 1999 он. ",
                "3. “Гэмт хэргийн нотолгооны ерөнхий онол” 2002 он. ",
                "4. МЗХ-ны захиалгаар “Залуу таны эрх зүйн мэдлэгт” УБ., 2003 он. ",
                "5. С.Төрмандахтай хамтран, “Гүйцэтгэх ажлын криминологи” 2004 он. ",
                "6. Д.Зүмбэрэллхамтай хамтран “Сэжигтэн, яллагдагчийн мэдүүлэг нотлох баримт болох нь” /сурагчийн ном, багшийн ном, сургалтын кино/ 2006 он",
                "7. Ж.Бямбаа, Т.Сүхбаатар нартай хамтран \"Эд мөрийн баримт\” /сурагчийн ном, багшийн ном, сургалтын кино/ 2006 он",
                "8. “Хүчирхийлэлд өртсөн хохирогчтой ажиллах цагдаагийн ажилтны гарын авлага” 2007 он. ",
                "9. “Хараагүй хүмүүст зориулсан эрх зүйн гарын авлага” 2007 он, ",
                "10. Г.Эрдэнэбаттай хамтран “Криминологи” гарын авлага. 2008 он",
                "11. Б.Туяатай хамтран “Нотолгооны онол арга зүй” гарын авлага. 2008 он",
                "12. Н.Баясгаланбат, С.Гантулга, С.Ичинхорлоо, Г.Лхагвамаа нартай хамтран “Хүн худалдаалах гэмт хэрэг /Сургагч багшийн гарын авлага, суралцагчдын гарын авлага/ 2009 он ",
                "13. Хамтын бүтээл. “Авлига, албан тушаалын гэмт хэрэг мөрдөх аргачлал” ерөнхий редактор, УБ., 2011.",
                "14. Ц.Цэлмэг, Г.Оюунболд, Б.Одонгэрэл нартай хамтран \"Авлига, албан тушаалын гэмт хэрэг\" Хуульчдад зориулсан гарын авлага. 2014 он",
                "15. Г.Оюунболд, Х.Баавгай нартай хамтарсан “Хүүхдийн байцаагчийн гарын авлага” 2017.",
                "16. Хамтын бүтээл “Эрүүгийн хууль, Эрүүгийн хэрэг хянан шийдвэрлэх тухай хууль” сургагч багш нарт зориулсан гарын авлага. 2017 он",
                "17. Хамтын бүтээл “Зөрчлийн хууль, Зөрчил шалган шийдвэрлэх тухай хууль” сургагч багш нарт зориулсан гарын авлага. 2017 он",
                "18. “Байгаль орчин, геологи, уул уурхайн хяналтад хүний эрх, хууль дээдлэх зарчмыг хангах нь сургалтын гарын авлага” Байгаль орчны улсын байцаагчид зориулсан гарын авлага. 2017 он",
                "19. Хамтын бүтээл “Мөнгө угаах болон терроризмын санхүүжүүлэхээс урьдчилан сэргийлэх гарын авлага” 2019 он",
                "20. “Банкнаас бусад санхүүгийн байгууллагын комплаенсын ажилтнуудын гарын авлага” 2019 он",
            ]
        },
        {
            "order" : 12,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Нэг сэдэвт бүтээл",

            "content_en" : [

            ],
            "content_mn" : [
                "1. “Криминологийн прогноз, төлөвлөлт” 1998 он. /2хх/",
                "2. “Эрүүгийн хэргийг нотлох ажиллагаан дахь онол, практикийн асуудал” 1999 он. ",
                "3. ХЗДХЯ-ны захиалгаар “Гэмт хэргийн шалтгаан, нөхцөлийг судлах аргачлал” 1999 он. ",
                "4. “Монгол дахь нотолгооны түүхэн уламжлал-1” 2001 он. ",
                "5. Б.Болдбаатартай хамтарсан. “Насанд хүрээгүй хүмүүсийн дунд криминологи судалгаа явуулах аргачлал” 2004. ",
                "6. Л.Отгонбаяртай хамтран “Хууль зүйн шинжлэх ухааны ном зүй” 2006 он ",
                "7. Э.Зууннасантай хамтран “БНХАУ-ын эрүүгийн ба эрүүгийн заргын хууль” 2006 он ",
                "8. Н.Баясгалантай хамтран “АНУ-ын эрүүгийн процесс” 2008 он.",
                "9. Авлига, албан тушаалын гэмт хэрэг мөрдөх аргачлал, ерөнхий редактор, УБ., 2011 он",
            ]
        },
        {
            "order" : 13,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Судалгааны тайлан",

            "content_en" : [

            ],
            "content_mn" : [
                "1. “Монгол Улсын хэмжээнд 1999-2000 онд бүртгэгдсэн гэмт хэргийн шалтгаан, нөхцөлийг судалж, дүгнэлт гаргах ажлын хэсгийн судалгааны тайлан”. 2005 он ",
                "2. Хүн худалдаалах гэмт хэргийн зураглал, УБ., 2010. Сүүдрийн тайлан илтгэл.",
                "3. Монгол Улсын авлигын индексийн 2009 оны тооцооны үр дүнгийн тайлан /дэлгэрэнгүй болон хураангуй, цахим хэлбэрээр/ УБ., 2010. ",
                "4. Төрийн байгууллагын шударга байдлын түвшин үнэлэх судалгааны ажлын тайлан, УБ., 2010. ",
                "5. Улс төр хууль хяналтын хүрээн дэх авлигын төсөөллийн судалгаа /2008, 2009, 2010, 2011 он/",
                "6. Монгол Улсын Мянганы хөгжлийн зорилтуудын хэрэгжилт /Үндэсний гурав дахь тайлан илтгэл/ УБ., 2010.",
                "7. Монгол Улсын Мянганы хөгжлийн зорилтуудын хэрэгжилт /Үндэсний дөрөв дэх тайлан илтгэл/ УБ., 2012.",
                "8. Хамтын бүтээл “Гэмт хэргийн цагаан ном 2016” 2017 он",
                "9. Хамтын бүтээл “Гэмт хэргийн цагаан ном 2017” 2019 он",
                "10. Хамтын бүтээл “Гэмт хэргийн цагаан ном 2018” 2019 он",
                "11. Хамтын бүтээл “Гэмт хэргийн цагаан ном 2019” 2020 он",
                "12. Авлигын гэмт хэргийн шийдвэрлэлтийн дүн шинжилгээ. 2021.",
            ]
        },
    ]

}

export const batjargal = {
    "consultant_id" : "52ed9980-e6c8-49c9-b299-9f869ebd5ba2",
    "profile_img" : "https://ik.imagekit.io/lltmixv8i/Consultant/52ed9980-e6c8-49c9-b299-9f869ebd5ba2.png?updatedAt=1694527529827",
    
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'firstName_latin'     : 'Batjargal',
    'firstName_cyrillic'  : 'Батжаргал',
    
    'displayName_cyrillic': 'Я.Батжаргал',
    'displayName_latin'   : 'Batjargal Yadam',
    
    'lastName_latin'      : 'Yadam',
    'lastName_cyrillic'   : 'Ядам',
    
    'title_cyrillic'      : 'Монголы улсын гавъяат эдийн засагч, ТМЗ',
    'title_latin'         : 'Certified Tax Accountant',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "text",
            
            "title_en" : "Profile",
            "title_mn" : "Миний тухай",

            "content_en" : "I have worked in the state in the Tax office for 29 out of 37 years. While working in the tax office, I have worked as head of the departments in capital and district tax offices, director of the department, chief state tax inspector, and leader of the \"Integrated Tax Management System Project\" in the General Tax Department.",
            "content_mn" : "Миний бие  улсад 37 жил, үүнээс татварын албанд 29 жил ажилласан. Татварын албанд ажиллах хугацаанд нийслэл, дүүргийн татварын газар, хэлтэст, тасаг, хэлтсийн дарга, ТЕГ-т газрын дарга, татварын улсын ахлах байцаагчаар, “Татварын удирдлагын нэгдсэн системийн төсөл”-ийн удирдагчаар тус тус ажилласан. 2021 оноос  Татварын мэргэшсэн зөвлөх нийгэмлэгт зөвлөхөөр ажиллаж байна. Нягтлан бодогч, эдийн засагч, эрх зүйч, төрийн удирдлагын менежерийн бакалавр, Эдийн засгийн магистр, МУМНБ, ТМЗ, Татварын шинжээч мэргэшлийн зэрэгтэй. Гавьяат эдийн засагч цолтой."
        },
        {
            "order" : 2,
            "type"  : "list_desc",
            
            "title_en" : "Education",
            "title_mn" : "Боловсрол",
            
            "content_en" : [
                {
                    "order" : 1,
                    "heading" : "/2015/ Bachelor of Lawyer, Orkhon University",
                    "subheading" : [
                        "Diploma thesis: Pre-trial resolution of tax disputes",
                    ]
                },
                {
                    "order" : 2,
                    "heading" : "/2001/ Bachelor of Public administration manager, National academy of Governance",
                    "subheading" : [
                        "Diploma thesis: International taxation and Double taxation agreements",
                    ]
                },
                {
                    "order" : 3,
                    "heading" : "/1998/ Master of Economics, the Mongolian University of Life sciences",
                    "subheading" : [
                        "Diploma thesis: Tax law",
                    ]
                },
                {
                    "order" : 4,
                    "heading" : "/1986/ Bachelor of Accountant, the Mongolian University of Life sciences",
                    "subheading" : [
                        "Diploma thesis: A new system of accountancy",
                    ]
                },
                {
                    "order" : 5,
                    "heading" : "24th senior high school",
                    "subheading" : []
                },
                {
                    "order" : 6,
                    "heading" : "/2001/ Mongolian Institute of Chartered Accountants",
                    "subheading" : [
                        "Certified public accountant of Mongolia",
                    ]
                },
                {
                    "order" : 7,
                    "heading" : "/2016/ Mongolian Association of Certified Tax Consultants ",
                    "subheading" : [
                        "Certified Tax Accountant",
                    ]
                },
            ],
            "content_mn" : [
                {
                    "order" : 1,
                    "heading" : "/2015/ Орхон ИС  бакалавр, Эрх зүйч",
                    "subheading" : [
                        "Дипломын сэдэв: Татварын маргааныг  шүүхийн өмнөх шатанд шийдвэрлэх нь ",
                    ]
                },
                {
                    "order" : 2,
                    "heading" : "/2001/ Удирдлагын академи бакалавр төрийн удирдлагын менежер",
                    "subheading" : [
                        "Дипломын сэдэв: Олон улсын татвар ба давхар татварын гэрээ",
                    ]
                },
                {
                    "order" : 3,
                    "heading" : "/1998/ ХААИС, Эдийн засгийн ухааны магистр",
                    "subheading" : [
                        "Дипломын сэдэв: Татварын эрх зүй",
                    ]
                },
                {
                    "order" : 4,
                    "heading" : "/1986/ ХААДС бакалавр, Нягтлан бодогч",
                    "subheading" : [
                        "Дипломын сэдэв: Нягтлан бодох бүртгэлийн шинэ систем",
                    ]
                },
                {
                    "order" : 5,
                    "heading" : "/1982/ он 24 дүгээр сургууль  бүрэн дунд ",
                    "subheading" : []
                },
                {
                    "order" : 6,
                    "heading" : "/2001/ МНБИ, Монгол улсын мэргэшсэн нягтлан бодогч ",
                    "subheading" : []
                },
                {
                    "order" : 7,
                    "heading" : "/2016/ ТМЗН, Татварын мэргэшсэн нягтлан бодогч ",
                    "subheading" : []
                },
                {
                    "order" : 8,
                    "heading" : "/2021/ ТМЗН, Татварын шинжээч",
                    "subheading" : []
                },
                {
                    "order" : 9,
                    "heading" : "1995 он Турк, 1998 он БНХАУ, 2001 онд ОХУ, 2002, 2015 Япон, 2010 Солонгос, 2015 онд Филиппин улсад татварын чиглэлээр, 2012 он АНУ, 2012 Герман улсад удирдлагын чиглэлээр сургаалтанд, 2022 онд Ази номхон дайлан бүсийн татварын мэргэшсэн зөвлөхийн чуулган,  ОУВС, Жайка төслийн хүрээнд хийгдсэн сургаалтанд тус тус  хамрагдсан. ",
                    "subheading" : []
                },
            ]
        },
        {
            "order" : 3,
            "type"  : "list_desc",
            
            "title_en" : "Work experience",
            "title_mn" : "Ажлын туршлага",

            "content_en" : [
                {
                    "order" : 1,
                    "heading" : "/June 2021 - Present/ Mongolian Association of Certified Tax Consultants",
                    "subheading" : [
                        "Professional services consultant",
                        "Teacher of the training committee",
                        "Member of the professional service management committee",
                        "Qualified tax consultant",
                        "Tax expert",
                    ]
                },
                {
                    "order" : 2,
                    "heading" : "/Jun 2019 - May 2021/ The taxpayer service department, General tax office",
                    "subheading" : [
                        "Chief State Inspector of Taxation",
                    ]
                },
                {
                    "order" : 3,
                    "heading" : "/Jan 2018 - Jun 2019/ General tax office",
                    "subheading" : [
                        "     Project leader of \"Integrated tax management system\"",
                    ]
                },
                {
                    "order" : 4,
                    "heading" : "/Feb 2017 - Jan 2018/ Taxpayer Liaison Office, General tax office",
                    "subheading" : [
                        "Chief State Inspector of Taxation",
                    ]
                },
                {
                    "order" : 5,
                    "heading" : "/Jan 2016 - Feb 2017/ Capital city Tax Office",
                    "subheading" : [
                        "Deputy chief",
                    ]
                },
                {
                    "order" : 6,
                    "heading" : "/Dec 2014 - Jan 2016/ Department of Tax Inspection and Management Methodology, General tax office",
                    "subheading" : [
                        "Head of the department",
                    ]
                },
                {
                    "order" : 7,
                    "heading" : "/May 2013 - Dec 2014/ Inspection Department, Capital city Tax Office",
                    "subheading" : [
                        "Head of the department",
                    ]
                },
                {
                    "order" : 8,
                    "heading" : "/Sep 2009 - May 2013/ Inspection Department, Bayangol district tax department",
                    "subheading" : [
                        "Unit chief of Inspection Department",
                    ]
                },
                {
                    "order" : 9,
                    "heading" : "/May 2005 - Sep 2009/ Inspection Department, Sukhbaatar district tax department",
                    "subheading" : [
                        "Unit chief of Inspection Department",
                    ]
                },
                {
                    "order" : 10,
                    "heading" : "/Jan 2000 - May 2005/ State Budget Revenue Control department, General tax office",
                    "subheading" : [
                        "Head of the Inspection Department",
                    ]
                },
                {
                    "order" : 11,
                    "heading" : "/Mar 1994 - Jan 2000/ Tax collection and service department, General tax office",
                    "subheading" : [
                        "State tax inspector",
                    ]
                },
                {
                    "order" : 12,
                    "heading" : "/Jul 1986 - Mar 1994/ TREST, Ministry of Agriculture",
                    "subheading" : [
                        "Senior and General accountant",
                    ]
                }
            ],
            "content_mn" : [
                {
                    "order" : 1,
                    "heading" : "2021.05.01-ээс Татварын мэргэшсэн зөвлөх нийгэмлэгийн  мэргэжлийн үйлчилгээ хариуцсан зөвлөх, сургалтын хороо, мэргэжлийн үйлчилгээ менежментийн хорооны гишүүн, татварын мэргэшсэн зөвлөх, татварын шинжээч, МХХ-ны сургагч багш",
                    "subheading" : [
                        "Татварын мэргэшсэн зөвлөх, татварын итгэмжлэгдсэн хуулийн этгээдийн үйл ажиллагаанд мэргэжлийн зөвлөх үйлчилгээг үзүүлэн ажиллаж байна.",
                        "Татвар төлөгчийн боловсролыг дээшлүүлэх чиглэлээр Монголын хуульчдын холбоо, Өмгөөлөгчдийн холбоо болон татвар төлөгчдөд сургалт зохион байгуулж байна.",
                        "“Генетикийн нөөц, уламжлалт мэдлэг ашигласны үр шимийн төлбөрийн тухай хууль”-ийн төсөл боловсруулан Засгийн газрын хуралдаанаар хэлэлцүүлэн тогтоол гаргуулах, “Ус бохирдуулсны төлбөрийн  хуульд нэмэлт өөрчлөлт оруулах хууль”-ийн төсөл, “Хөрс хамгаалах, цөлжилтөөс сэргийлэх тухай хууль”-ийн шинэчилсэн найруулгын төсөл боловсруулах ажлын зөвлөхөөр тус тус ажиллаж байна.",
                    ]
                },
                {
                    "order" : 2,
                    "heading" : "/2019.06.01-2021.05.01/ ТЕГ-ын Татвар төлөгчид үйлчлэх газрын Татварын улсын ахлах байцаагч",
                    "subheading" : [
                        "Татвар төлөгчийн үйлчилгээг сайжруулах, татварын тайлангийн цахим системийн нэвтрүүлэн, татвар төлөгчийн бүртгэл, тайлангийн иж бүрэн цахим системийг нэвтрүүлсэн. Татварын ерөнхий хууль, аж ахуйн нэгжийн орлогын албан татварын хууль, хувь хүний орлогын албан татварын хуулийн шинэчлэн найруулгыг хэрэгжүүлэх 47 журмыг боловсруулж батлуулж, 10 татварын тайлангийн маягтыг боловсруулан батлуулсан. Бараа бүртгэлийн нэгдсэн систем, гэрээ бүртгэлийн систем, цахим тайлангийн системийн нэвтрүүлэлтийг хариуцан ажилласан. Нэвтрэлтийн нэгдсэн ",
                        "2019 онд Мөнгө угаахтай тэмцэх санхүүгийн арга хэмжээ авах байгууллага /Financial Action Task Force буюу цаашид “ФАТФ” гэх/ нь Монгол Улсын хоёр дахь удаагийн харилцан үнэлгээний үр дүнг хамгаалахад 5 зөвлөмжийн биелэлтийг тайлагнан хангалттай  үнэлгээг авсан болно.  ",
                        "Татварын удирдлагын нэгдсэн системийн гарын авлага /23 х.х/ 2019,  Татварын шинэчлэл эмхэтгэл /43,5 х.х/ 2019,  Татварын хууль тогтоомж, тайлангийн маягтын эмхэтгэл /98х.х/ 2020,  Бүртгэлийн хууль тогтоомжийн эмхэтгэл/ 60х.х 2020/, Татварын бүртгэл / татварын мэргэшсэн зөвлөхийн сургалтад/ 2021,  Татварын онол / татварын мэргэшсэн зөвлөхийн сургалтад/ 2021, Татварын тогтолцоо арга зүй, судалгаа, шинэчлэл /9.3 х.х / 2021,  Гарын авлага / татвар төлөгч иргэнд зориулсан/  номыг бэлтгэн хэвлүүлж олон нийтийн хүртээл болгосон.",
                    ]
                },
                {
                    "order" : 3,
                    "heading" : "/2018.01.02- 2019.06.01/ Татварын удирдлагын нэгдсэн систем “төслийн удирдагч",
                    "subheading" : [
                        "ТУНС нь татварын албаны бүх үйл ажиллагаа, татвар төлөгчийн үйлчилгээг бүрэн цахимжуулах 32 модуль бүхий  “Татварын удирдлагын нэгдсэн систем” -ийг нэвтрүүлж, төслийн удирдагчаар АХБ-ны зээлээр  Татварын албаны дэд бүтцийг сайжруулах төслийн техникийн баримт бичгийг боловсруулах, санамж бичиг, зээлийн гэрээний төсөл боловсруулах ажлыг зохион байгуулж хамтран ажилласан.",
                        "Татварын ерөнхий хууль, Аж ахуйн нэгийн орлогын албан татварын хууль, Хувь хүний орлогын албан татварын хуулийг боловсруулах ажлын хэсэгт ажилласан.",
                    ]
                },
                {
                    "order" : 4,
                    "heading" : "/2017.02.15 –2018.01.02/ ТЕГ-ын Татвар төлөгчтэй харилцах газрын  Татварын улсын ахлах байцаагч",
                    "subheading" : [
                        "Хуулийн этгээдийн болон нам, холбоо, гадаадын төрийн бус байгууллагын төлөөлөгчийн газрын мэдээлэл, түүнийг татвар төлөгчийн бүртгэлд бүрэн бүртгэх асуудлыг холбогдох төрийн байгууллагуудтай хамтран зохион байгуулсан. ",
                        "Байгалын нөөц ашигласны төлбөрийн ТТ17- ТТ20 , АМНАТ-ийн маягтыг боловсруулан батлуулж мөрдүүлсэн. ",
                    ]
                },
                {
                    "order" : 5,
                    "heading" : "/2016.01.22 -2017.02.15/   Нийслэлийн татварын газрын орлогч дарга ",
                    "subheading" : [
                        "Улсын болон орон нутгийн төсвийн орлогыг ханган биелүүлэх, татварын өр барагдуулах, тавар хураалтын  ажлыг мэргэжил арга зүйн  удирдлагаар ханган ажиллаж байна.",
                    ]
                },
                {
                    "order" : 6,
                    "heading" : "/2014.12.24 -2016.01.22/  ТЕГ-ын Татварын хяналт шалгалт удирдлага арга зүйн газрын дарга",
                    "subheading" : [
                        "Нэмэгдсэн өртгийн албан татварын хуулийн шинэчилсэн найруулга, Нийслэл хотын албан татварын тухай хуулийн төслийн боловсруулах ажлын хэсэгт ажилласан. ",
                        "Цахим төлбөрийн баримтын систем\ebarimt.mn\,Эрсдэлийн удирдлага татварын хяналт шалгалтын  программ/Fisher/-ыг нэвтрүүлсэн. ",
                        "Зөрчлийн тухай хууль, Эрүүгийн хуулийн шинэчилсэн найруулгыг боловсруулах  ажлын хэсэгт ажиллан татварын зөрчил, гэмт хэргийн заалтыг боловсруулан  батлуулав.",
                        "Татварын хуулийн дагуу ногдуулсан хүү, торгууль, алдангийг  захиргааны зөрчил захиргааны шийтгэвэрт хамааруулах асуудлыг УИХ-ын Хууль зүйн байнгын хороо, өршөөлийн хуулийг хэрэгжүүлэх улсын комиссын хуралдаанаар шийдвэрлүүлсэн",
                        "“ЭЗИТБД тухай хуулийн хэрэгжүүлэх журам”-ыг  боловсруулан батлуулж, хэрэгжилтийг удирдан ажилласан.  ",
                        "НӨАТ-ийн хуурамч бичилт бүхий падаан ашиглан татвараас зайлсхийх явдалтай  тэмцэж нийт 200 гаруй Аж ахуйн нэгжийн тараасан хий бичилт бичилт бүхий падаантай холбоотой зөрчлийг шалган шийдвэрлэж 13,5 тэрбум,  хууль, хүчний  байгууллагатай хамтран зохион байгуулснаар 27,8 тэрбум төгрөгийн орлогыг улсын төсөвт нөхөн төлүүлсэн байна. ",
                    ]
                },
                {
                    "order" : 7,
                    "heading" : "/2013.05.19-2014.12.24/   НТГ-ын Хяналт шалгалтын  Хэлтсийн дарга",
                    "subheading" : [
                        "Нийслэлийн хэмжээнд хяналт шалгалтын байцаагчдыг мэргэжил арга зүйгээр хангах, эрсдэлд суурилсан хяналт шалгалтыг төлөвлөх, гүйцэтгэлд хяналт тавих   ажлыг зохион байгуулсан ",
                        "“Татварын хяналт шалгалтын  үйл ажиллагааны журам”-ыг шинэчлэн  боловсруулж ТЕГ-аар батлуулсныг өнөөг хүртэл  хяналт шалгалтын ажлын үйл ажиллагааны  үндсэн баримт бичиг болгон ашиглаж байна. ",
                    ]
                },
                {
                    "order" : 8,
                    "heading" : "/2009.09.01-2013.05.19/ Баянгол дүүргийн татварын хэлтсийн Хяналт шалгалтын тасгийн дарга",
                    "subheading" : [
                        "Байцаагчдын мэргэжил, ёс зүйн чадварыг дээшлүүлэх,  ТУБ-ын акт, дүгнэлтийн биелэлтийг хангуулахад анхаарч ажилласан. 2009 оноос 2013 онд Татварын хяналт шалгалтаар илрүүлсэн зөрчил 70   хувиар, төсөвт төвлөрүүлсэн хяналт шалгалтын орлого 150 хувиар тус тус өссөн.",
                        "Байгалийн нөөц ашигласны төлбөрийн хууль, агаарын бохирдлын төлбөрийн тухай хуулийн төслийг боловсруулах ажлын хэсэгт ажилласан.",
                    ]
                },
                {
                    "order" : 9,
                    "heading" : "/2005.05.01-2009.09.01 Сүхбаатар дүүргийн татварын хэлтсийн Хяналт шалгалтын тасгийн дарга",
                    "subheading" : [
                        "Хяналт шалгалтын ажил эрхлэх чиг үүрэг бүхий  байцаагчдын мэргэжлийн ур чадварыг дээшлүүлэх, байцаагчдын ажлыг үнэлэх үзүүлэлтийг шинээр хийж мөрдүүлсэн. ",
                        "Татварын өршөөлийн хууль, Татварын зарим хууль болон нийгмийн даатгалын тухай хуулийг хэрэгжүүлэх тухай хуулийн хуулийн хэрэгжилтийг хангах ажлыг дүүргийн хэмжээнд зохион байгуулах, тайлан хүлээн авах, нэгтгэл мэдээг гаргах ажлыг зохион байгуулан гүйцэтгэж ажилласан. ",
                        "Татварын ерөнхий хууль, аж ахуйн нэгжийн орлогын албан татварын хууль хувь хүний орлогын албан татварын хуулийн шинэчлэн найруулах ажлын хэсэгт ажилласан.",
                    ]
                },
                {
                    "order" : 10,
                    "heading" : "/2000.01. 10-2005.05.01/ ТЕГ-ын УТОХГ-ын хяналт шалгалтын хэлтсийн дарга",
                    "subheading" : [
                        "Дипломат болон олон улсын байгууллага, төсөл хөтөлбөрийн  НӨАТ-ын буцаан олголтыг  тусгай падаан ашиглан хийх ажлыг хэрэгжүүлсэн"
                    ]
                },
                {
                    "order" : 11,
                    "heading" : "/1994.03.15-2000.01.10/ ТЕГ-ын татвар хураалт, үйлчилгээний хэлтсийн татварын улсын байцаагч",
                    "subheading" : [
                        "Татварын ерөнхий хууль, Хүн амын орлогын албан татварын хууль, Аж ахуйн нэгж байгууллагын орлогын албан татварын хууль, Нэмэгдсэн өртгийн албан татварын хуулийг боловсруулах, холбогдох журам, заавар, аргачлалыг боловсруулан батлуулах, хуулийн хэрэгжилтийг хангах ажлыг хийж гүйцэтгэсэн"
                    ]
                },
                {
                    "order" : 12,
                    "heading" : "/1986.07.01- 1994.03.15/ ХААЯ-ны ЦСТЗУТ ахлах, ерөнхий ня-бо ",
                    "subheading" : [
                        "Тухайн трестийн санхүүгийн үйл ажиллагааг журнал ордерын системд бүрэн нэвтрүүлсэн ",
                        "Трестийн хувьчлал, хувьцаат компани байгуулах үйл ажиллагааг удирдах хийсэн.",
                    ]
                },
                
            ]
        },
        {
            "order" : 4,
            "type"  : "list",
            
            "title_en" : "Law and regulations",
            "title_mn" : "",

            "content_en" : [
                "Worked in the working team for drafting the following rules:",
                " • Working on the “On soil protection and prevention from desertification” law draft - 2023",
                " • Added-value tax law, Law of Torts, Criminal law amendment act - 1998, 2015",
                " • General tax law, Economic entity, and Organization Income tax law, Personal income tax law - 2007, 2019",
                " • On air pollution charge law - 2010",
                " • Water pollution charge law, Natural resource royalty law, Capital city taxation law - 2012, 2014",
                "   One hundred fifteen procedures, methods, and instructions for implementing the law were developed and approved.",
                " • New and amended corporate and personal income tax forms, natural resource royalties, mineral payments, and commonly distributed mineral payment reports have been developed and approved.",
            ],
            "content_mn" : [

            ]
        },
        {
            "order" : 5,
            "type"  : "list",
            
            "title_en" : "Digitalization of the tax office",
            "title_mn" : "",

            "content_en" : [
                " • In 2015, broadcasted the Tax inspection risk program.",
                " • In 2019, 32 modules of the \"Integrated tax management system\" of the tax office, payment receipt system \ebarimt.mn\, and integrated login system \etax.mta.mn\ were managed and policies were developed and broadcasted.",
            ],
            "content_mn" : [
                
            ]
        },
        {
            "order" : 6,
            "type"  : "list",
            
            "title_en" : "Other works",
            "title_mn" : "",

            "content_en" : [
                " • Implementing the Tax Amnesty Law, some Tax Laws, the Law on Social Insurance, and the Law on Economic Transparency organized at the level of the tax department, and the results reported to the Parliament and the Government.",
                " • In 2019, the Financial Action Task Force (FATF) reported implementing five recommendations to protect the results of the second round of mutual evaluation of Mongolia.",
            ],
            "content_mn" : [
                
            ]
        },
        {
            "order" : 7,
            "type"  : "list",
            
            "title_en" : "Published books",
            "title_mn" : "",

            "content_en" : [
                " • Guide to the Integrated Tax Administration System /23 pages/, 2019",
                " • Tax reform bulletin /43.5 pages/, 2019",
                " • Compilation of tax laws and reporting forms /98 pages/, 2020",
                " • Collection of registration laws /60 pages/, 2020",
                " • Tax registration /in the training of a qualified tax consultant/, 2021",
                " • Tax theory / in the training of a qualified tax consultant/, 2021",
                " • Methodology, research, and reform of the tax system / 9.3 pages/, 2021",
                " • Manual /for taxpayers/",
            ],
            "content_mn" : [
                
            ]
        },
        {
            "order" : 8,
            "type"  : "list",
            
            "title_en" : "Honors",
            "title_mn" : "",

            "content_en" : [
                " • Honored economist ",
                " • Order of the Red Banner of Labor Merit",
                " • Polar star ",
                " • Honorary medal of Labor",
                " • Financial best worker",
            ],
            "content_mn" : [
                
            ]
        },
    ]

}

export const tungalagnaran = {
    "consultant_id" : "43019e09-db35-45ec-98e0-76ff7ff08144",
    "profile_img" : "https://ik.imagekit.io/lltmixv8i/Consultant/43019e09-db35-45ec-98e0-76ff7ff08144.png?updatedAt=1694527531378",
    
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'firstName_latin'     : 'Tungalagnaran',
    'firstName_cyrillic'  : 'Тунгалагнаран',
    
    'displayName_cyrillic': 'Б.Тунгалагнаран',
    'displayName_latin'   : 'Tungalagnaran Bayaraa',
    
    'lastName_latin'      : 'Bayaraa',
    'lastName_cyrillic'   : 'Баяраа',
    
    'title_cyrillic'      : 'Өмгөөллийн “Эф Би Эл Си” ХХН-н үүсгэн байгуулагч, гүйцэтгэх захирал',
    'title_latin'         : 'CEO, Advocate',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Боловсрол",

            "content_en" : [],
            "content_mn" : [
                " • /2019/ Шихихутуг Их Сургууль- хууль зүйн ухааны магистр ",
                " • /2002-2006/ Цагдаагийн академи, эрх зүй, бакалавр",
                " • /1992-2002/ ЕБ-н 14-р дунд сургууль ",
            ]
        },
        {
            "order" : 2,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Ажилласан туршлага",

            "content_en" : [],
            "content_mn" : [
                " • /2007-2008/ “Хүний эрх-Аюулгүй орчин” ТББ-д хуульч",
                " • /2008-2012/ Барилга угсралтын Вилмар ХХК-д  хуулийн зөвлөх ",
                " • /2012-2015/ Ашигт малтмалын чиглэлээр үйл ажиллагаа явуулдаг БНСУ-ын хөрөнгө оруулалттай “Хотгор шанага” ХХК-д хуулийн зөвлөх",
                " • /2015-2017/ Өмгөөллийн “Эл Би Партнерс” ХХН-н өмгөөлөгч ",
                " • /2018/ “Санхүү бизнесийн эрх зүйн судалгааны хүрээлэн” ТББ-н гүйцэтгэх захирал",
                " • /2018/ Өмгөөллийн “Эф Би Эл Си” ХХН-н үүсгэн байгуулагч, гүйцэтгэх захирал",
            ]
        },
        {
            "order" : 3,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Сонгуульт ажил",
            
            "content_en" : [],
            "content_mn" : [
                " • “Хил Хязгааргүй Хуульчид” ТББ-н УЗ-н гишүүн ",
                " • “Санхүү, бизнесийн эрх зүйн судалгааны хүрээлэн” ТББ-н үүсгэн байгуулагч,  Гүйцэтгэх захирал",
                " • Үндэсний зөвлөгч ХХК-н захирал",
                " • Өмгөөллийн Эф Би Эл Си ХХН-н үүсгэн байгуулагч, гүйцэтгэх захирал ",
            ]
        },
        {
            "order" : 4,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Мэргэшсэн байдал",
            
            "content_en" : [],
            "content_mn" : [
                " • АОЭХолбооны “Эрсдэлийн менежмент”-н сургалт",
                " • КЗҮЗөвлөл, ҮЦАЭХолбооны хамтарсан “Компаний ТУЗ-н гишүүн, нарийн бичгийн дарга нарт зориулсан сургалт     ",
                " • Азийн Хөгжлийн сан, Прокурорын ерөнхий газрын хамтарсан “Эдийн засгийн гэмт хэргийн суурь” сургалт      ",
                " • Комплаенс ",
            ]
        },
        {
            "order" : 5,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Судалгаа хийсэн байдал",
            
            "content_en" : [],
            "content_mn" : [
                " • Жижиг дунд бизнес эрхлэгчдэд төрийн үйлчилгээ үзүүлж буй албан хаагчдад зориулсан гарын авлага- 2021 он ",
                " • Төрийн албан хаагчийн ёс зүйн хэм хэмжээний хэрэгжилт судалгаа -2020 он ",
                " • Жендерийн тэгш эрхийн тухай хуулийн үр дагаврын судалгаа-2020 он ",
                " • Даатгалын зохицуулалтын эрх зүйн актын эмхэтгэл -2020 он ",
                " • Авлигын хэргийг шүүхээр хянан шийдвэрлэж байгаа практик судалгаа-ННФ-2020 он ",
                " • “Зөрчлийн хуулийн хэрэгжилтэд хийсэн дүн шинжилгээ” судалгааны багийн гишүүн -2019 он ",
                " • Байгаль орчны яамны захиалгаар “Салбарын багц хуульд нэмэлт өөрчлөлт оруулах судалгаа” багийн гишүүн 2019 он ",
                " • “Санхүүгийн зохицуулах хороо шүүх хуралдаанд нэхэмжлэгч, хариуцагчаар оролцсон хэрэг маргаанд хийх дүн шинжилгээ”  2018 он ",
                " • “Санхүүгийн зохицуулах хорооны улсын байцаагчийн Зөрчлийн хуулийн дагуу торгууль оногдуулсан шийдвэрт хийх дүн шинжилгээ хийх” судалгааны ажил -2018 он ",
                " • МҮОНРТелевизийн Хөдөлмөрийн харилцааны бүх дүрэм журам, хөдөлмөрийн гэрээний нийцэл хийх ажилд багийн гишүүнээр 2022",
                " • Улаанбаатар хотын даатгал ХХК-ний Даатгалын ажилтны ёс зүйн дүрэм, ёс зүйн хорооны ажиллах журмын төслийг Хуулийн фирмээрээ хийж гүйцэтгэсэн 2021",
                " • Аржанчивлан рашаан сувилал ХХК-д Хөдөлмөрийн харилцааны бүх дүрэм журмын нийцэл 2022",
            ]
        },
        {
            "order" : 6,
            "type"  : "list",
            
            "title_en" : "",
            "title_mn" : "Гавьяа шагнал",
            
            "content_en" : [],
            "content_mn" : [
                " • МЗХолбооны “Манлайлагч Оюутан” алтан медаль -2005 онд",
                " • МӨХ-ны “Өмгөөлөгчийн Алдар” алтан медаль-2014 онд",
                " • МЗХолбооны “Тэргүүний Залуу Хуульч” цол тэмдгээр 2015 он ",
                " • МЗХ-ны “Тэргүүний Залуу” цол тэмдэг -2021 онд тус тус шагнагдаж байсан",
            ]
        },
    ]   
}

export const tsetsgee = {
    "consultant_id" : "39ce6cb4-d53a-42b7-978f-ddf93ae180fd",
    "profile_img" : "https://ik.imagekit.io/lltmixv8i/Consultant/39ce6cb4-d53a-42b7-978f-ddf93ae180fd.png?updatedAt=1694527528785",
    
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'firstName_latin'     : 'Tsetsgee',
    'firstName_cyrillic'  : 'Цэцгээ',
    
    'displayName_cyrillic': 'Г.Цэцгээ',
    'displayName_latin'   : 'Tsetsgee Gombodorj',
    
    'lastName_latin'      : 'Gombodorj',
    'lastName_cyrillic'   : 'Гомбодорж',
    
    'title_cyrillic'      : 'Эрх зүйч, Хууль зүйн магистр',
    'title_latin'         : '',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Ажлын туршлага",

            "content_en" : [],
            "content_mn" : [
                "/1990-1994/ НАХЯ-ны харьяа Хорих байгууллагад тоо бүртгэлийн байцаагч, сургалт арга зүйн ажилтан, ",
                "/1995-1998/ Нийслэлийн Өмгөөлөгчдийн зөвлөлийн гишүүн, өмгөөлөгч",
                "/1998-2014/ Үл хөдлөх эд хөрөнгийн бүртгэлийн газар, Улсын бүртгэлийн ерөнхий газарт улсын бүртгэгч, хэлтсийн дарга,",
                "/2015-2019/ ХЗДХЯ-нд газрын дарга, ахлах мэргэжилтэн,",
                "/2019-2020/ УБЕГ-ын Бодлого, төлөвлөлт, дүн шинжилгээний газрын дарга.            ",
                "2020 оноос МХХ, ХЗҮХ-ийн сургагч багш",
            ]
        }
    ]
}

export const enkhjargal = {
    "consultant_id" : "9e8350f7-5e8b-44e6-95e7-156069321c58",
    "profile_img" : "https://ik.imagekit.io/lltmixv8i/Consultant/9e8350f7-5e8b-44e6-95e7-156069321c58.png?updatedAt=1694527529596",
    
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'firstName_latin'     : 'Enkhjargal',
    'firstName_cyrillic'  : 'Энхжаргал',
    
    'displayName_latin'   : 'Enkhjargal Makhbal',
    'displayName_cyrillic': 'М.Энхжаргал',
    
    'lastName_latin'      : 'Makhbal',
    'lastName_cyrillic'   : 'Махбал',
    
    'title_cyrillic'      : 'Өмгөөлөгч',
    'title_latin'         : '',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Боловсрол",

            "content_en" : [],
            "content_mn" : [
                "/2022-2023/ Энэтхэг улсын Defence Services Staff College, management and public relations",
                "/2008-2010/ МУИС-ХЗС, хууль зүйн магистр           ",
                "/2003-2007/ Цагдаагийн академи, эрх зүй, бакалавр",
                "/1992-2002/ ЕБ-н 41-р дунд сургууль ",
            ]
        },
        {
            "order" : 2,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Ажилласан туршлага",

            "content_en" : [],
            "content_mn" : [
                "/2012-2015/ “Жигүүр гранд групп”-н хүний нөөц, хяналтын албаны дарга",
                "/2015-2018/ Хуульч, өмгөөлөгч",
                "/2018-2019/ “Милко” ХХК-н хуулийн албаны дарга ",
                "2018 Өмгөөллийн “Эф Би Эл Си”  ХХН-н үүсгэн байгуулагч, партнер өмгөөлөгч",
            ]
        },
        {
            "order" : 3,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Мэргэшсэн байдал",

            "content_en" : [],
            "content_mn" : [
                "Эрүү, иргэн, захиргаа, хөдөлмөрийн эрх зүйн харилцаагаар мэргэшсэн."
            ]
        },
        {
            "order" : 4,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Судалгаа хийсэн байдал",

            "content_en" : [],
            "content_mn" : [
                "Төрийн албан хаагчийн ёс зүйн хэм хэмжээний хэрэгжилт судалгаа, 2020",
                "Жэндэрийн тэгш эрхийн тухай хуулийн үр дагаврын судалгаа, 2020",
                "“Төрийн байгууллагын ил тод байдал ба ЖДҮ” гарын авлага, 2016",
                "Ар жанчивлан рашаан сувиллын ажилчдын “Ёс зүйн дүрэм”-ийг боловсруулсан",
                "Улаанбаатар хотын даатгал ХХК-н “Ёс зүйн хорооны журам”-ыг боловсруулсан.",
            ]
        },
        {
            "order" : 5,
            "type"  : "list",

            "title_en" : "",
            "title_mn" : "Гавьяа шагнал",

            "content_en" : [],
            "content_mn" : [
                "МЗХолбооны “Тэргүүний залуу” цол тэмдэг -2021 онд",
                "МӨХ-ны “Өмгөөлөгчийн Алдар” алтан медаль-2017 онд",
                "МӨХолбооны “Монголын Тэргүүний Өмгөөлөгч” цол тэмдэг- 2019 он",
                "Хууль зүй дотоод хэргийн яамны “Жуух бичиг”-р 2022 он ",
            ]
        },

    ]
}

export const tsengelbaatar = {
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'consultant_id' : '55ac6247-a807-46aa-a955-4dda3cba83b2',
    'displayName_cyrillic'   : 'О.Цэнгэлбаатар',
    'firstName_cyrillic'     : 'Цэнгэлбаатар',
    'lastName_cyrillic'      : 'Оюун',
    'title_cyrillic'         : 'Стратегийн зөвлөх',
    'displayName_latin'   : 'Tsengelbaatar Oyun',
    'firstName_latin'     : 'Tsengelbaatar',
    'lastName_latin'      : 'Oyun',
    'title_latin'         : 'Strategy consultant',
    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/55ac6247-a807-46aa-a955-4dda3cba83b2.png?updatedAt=1694527529622',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "list",

            "title_en" : "Education",
            "title_mn" : "Боловсрол",

            "content_en" : [
                "Doctoral student of law at the Mongolian State University of Education",
                "/2004-2006/ National University of Mongolia - School of Law, Bachelor of Laws",
                "/1998-2002/ Police Academy, Bachelor of Laws",
                "/1980-1990/ 2nd Secondary School of General Education",
            ],
            "content_mn" : [
            ]
        },
        {
            "order" : 2,
            "type"  : "list",

            "title_en" : "Work experience",
            "title_mn" : "Ажилласан туршлага",

            "content_en" : [
                "/1998-2008/	The National Police Agency",
                "/2008-2012/	Independent Authority Against Corruption of Mongolia",
                "/2012-2023/	CEO of Prami Partner LLC",
                "/2018/		Founder of \"FBLC\" law firm.",
            ],
            "content_mn" : [
            ]
        },
        {
            "order" : 3,
            "type"  : "list",

            "title_en" : "Elected work",
            "title_mn" : "",

            "content_en" : [
                "/2021/ 	Chairman of the Board of Directors of the Information Technology and Communication Development Association of Mongolia and Korea",
                "/2004/	Interpol Organization Office Communication Training, Istanbul, Turkey",
                "/2004/	\"Mining Safety\" in Weihai City, China",
                "/2005/	\"Mining Safety\" in Australia",
                "/2009/	Internal audit of the organization in Seoul, Korea ",
                "/2010/	\"Mining Safety\" in Australia",
                "/2014/	Head of Internal Inspection Department of Sainshand Industrial Park",
            ],
            "content_mn" : [
            ]
        },

    ]
}

export const batdulam = {
    "familyName_cyrillic" : "",
    "familyName_latin"    : "",
    
    'consultant_id' : '55ac6247-a807-46aa-a955-4dda3cba83b2',
    'displayName_cyrillic'   : 'О.Цэнгэлбаатар',
    'firstName_cyrillic'     : 'Цэнгэлбаатар',
    'lastName_cyrillic'      : 'Оюун',
    'title_cyrillic'         : 'Стратегийн зөвлөх',
    'displayName_latin'   : 'Tsengelbaatar Oyun',
    'firstName_latin'     : 'Tsengelbaatar',
    'lastName_latin'      : 'Oyun',
    'title_latin'         : 'Strategy consultant',
    'profile_img'   : 'https://ik.imagekit.io/lltmixv8i/Consultant/55ac6247-a807-46aa-a955-4dda3cba83b2.png?updatedAt=1694527529622',
    
    "content"     : [
        {
            "order" : 1,
            "type"  : "list",

            "title_en" : "Education",
            "title_mn" : "Боловсрол",

            "content_en" : [
                "Doctoral student of law at the Mongolian State University of Education",
                "/2004-2006/ National University of Mongolia - School of Law, Bachelor of Laws",
                "/1998-2002/ Police Academy, Bachelor of Laws",
                "/1980-1990/ 2nd Secondary School of General Education",
            ],
            "content_mn" : [
            ]
        },
        {
            "order" : 2,
            "type"  : "list",

            "title_en" : "Work experience",
            "title_mn" : "Ажилласан туршлага",

            "content_en" : [
                "/1998-2008/	The National Police Agency",
                "/2008-2012/	Independent Authority Against Corruption of Mongolia",
                "/2012-2023/	CEO of Prami Partner LLC",
                "/2018/		Founder of \"FBLC\" law firm.",
            ],
            "content_mn" : [
            ]
        },
        {
            "order" : 3,
            "type"  : "list",

            "title_en" : "Elected work",
            "title_mn" : "",

            "content_en" : [
                "/2021/ 	Chairman of the Board of Directors of the Information Technology and Communication Development Association of Mongolia and Korea",
                "/2004/	Interpol Organization Office Communication Training, Istanbul, Turkey",
                "/2004/	\"Mining Safety\" in Weihai City, China",
                "/2005/	\"Mining Safety\" in Australia",
                "/2009/	Internal audit of the organization in Seoul, Korea ",
                "/2010/	\"Mining Safety\" in Australia",
                "/2014/	Head of Internal Inspection Department of Sainshand Industrial Park",
            ],
            "content_mn" : [
            ]
        },

    ]
}