import { createSlice } from '@reduxjs/toolkit';

export const person_slice = createSlice({
    name : 'person',
    initialState: {
        value: {
            "loaded" : false,
            "content" : []
        }
    },
    reducers: {
        setPerson: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { setPerson } = person_slice.actions

export default person_slice.reducer