import React, { useState } from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import { Button, CardActionArea, CardActions, Paper, Box, Divider, Typography } from '@mui/material';

// Typegraphy theme
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

// Map
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../app/slices/appSlice';


// Icon
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';

function CustomFooter() {
  // For language selection
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.value.language);


  const { rive, RiveComponent } = useRive({
    src: '/map.riv',
    stateMachines: "main_state",
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center
    }),
    autoplay: true,
  });

  const theme = createTheme();
  theme.typography.h2 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '2rem',
      color: 'white'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      color: 'white'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      color: 'white'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      color: 'white'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
      color: 'white'
    },
  };

  // Statics???
  const title = {
    'en': 'Contact us',
    'mn': 'Бидэнтэй холбоо барих',
    'cn': '联系我们'
  }
  const address = {
    'en': 'SPS building office, No. 501, Green Lake Street, Sukhbaatar District, Ulaanbaatar City',
    'mn': 'Улаанбаатар хот, Сүхбаатар дүүрэг, 9-хороо, Ногоон нуурын гудамж, SPS building оффис, 501 тоот',
    'cn': '乌兰巴托市苏赫巴托区青湖街501号SPS大楼办公室'
  }
  /* 
      {
          'en' : '',
          'mn' : '',
          'cn' : ''
      }
  */

  return (
    <Box id='contact'
      sx={{
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: {
          xs: '10px', sm: '20px', md: '100px', lg: '150px', xl: '190px'
        },
      }}
    >
      <Box sx={{ width: '100%', mt: '4rem', mb: '2rem' }}>
        <ThemeProvider theme={theme}>
          <Typography variant='h2' >
            {title[language]}
          </Typography>
        </ThemeProvider>
      </Box>


      {/* TODO: Footer map? */}
      {/* <Box
                sx={{
                    height: { xs : '270px', sm : '500px', md : '500px', lg : '500px', xl : '500px' },
                    width: { xs : '150%', sm : '150%', md : '100%', lg : '95%', xl : '80%' },
                    display: "flex", 
                    flexDirection : "row",
                }}
            >
                <RiveComponent style={{
                        height: '88%',
                        width: '100%',
                    }}
                />
            </Box> */}

      <Box sx={{
        height: '10px',
        width: '100%',
        opacity: 0.4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: '20px',
      }}>
        <Divider variant="middle" sx={{
          width: '100%', border: '1px solid', color: 'white'
        }} />
      </Box>

      {/***************************** Bottom card ****************************/}
      <Box sx={{
        width: '100%',

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      >
        <Grid container
          spacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        >

          <Grid item xs={12} sm={4} md={4} >
            <Box sx={{
              height: '100%',
              alignItems: "center",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
            }}
            >
              {/******************** Company: Contacts *********************/}
              <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} >
                <Typography sx={{ fontWeight: 'bold', height: '5rem' }} style={{ color: 'white' }} >
                  FBLC
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <LocalPhoneSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        (+976) 89115075
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <EmailSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        undesniizovlogch@gmail.com
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <RoomSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        {address[language]}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4} >
            <Box sx={{
              height: '100%',
              alignItems: "center",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
            }}
            >
              {/******************** Company: Contacts *********************/}
              <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} >
                <Typography sx={{ fontWeight: 'bold', height: '5rem' }} style={{ color: 'white' }} >
                  САНХҮҮ, БИЗНЕСИЙН ЭРХ ЗҮЙН СУДАЛГААНЫ ХҮРЭЭЛЭН
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <LocalPhoneSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        (+976) 89115075
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <EmailSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        undesniizovlogch@gmail.com
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <RoomSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        {address[language]}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4} >
            <Box sx={{
              height: '100%',
              alignItems: "center",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
            }}
            >
              {/******************** Company: Contacts *********************/}
              <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} >
                <Typography sx={{ fontWeight: 'bold', height: '5rem' }} style={{ color: 'white' }} >
                  DHH
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <LocalPhoneSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        (+976) 70118688
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <EmailSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                        info@dhhmongolia.mn
                      </Typography>
                    </Box>
                    <Box sx={{ ml: '5%', mr: '5%', mt: '10px', mb: '10px', width: '90%', display: 'flex', flexDirection: 'row' }} >
                      <RoomSharpIcon style={{ color: '#f37520' }} />
                      <Typography sx={{ ml: '5%' }} style={{ color: 'white' }} >
                      Улаанбаатар хот, Сүхбаатар дүүрэг, 9-хороо, Ногоон нуурын гудамж, SPS building оффис, 503 тоот
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

        </Grid>
      </Box>

    </Box>
  );
}

export default CustomFooter;