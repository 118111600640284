import { configureStore } from '@reduxjs/toolkit';

import nconsultReducer from './slices/nconsult_slice';
import consultantsReducer from './slices/consultants_slice';
import companyReducer from './slices/company_slice';
import appReducer from './slices/appSlice';
import personReducer from './slices/person';
import person from './slices/person';

export default configureStore({
    reducer: {
        nconsult: nconsultReducer,
        consultants: consultantsReducer,
        company: companyReducer,
        app: appReducer,
        person: personReducer
    },
})