import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import parse from 'html-react-parser';
import Header from "../Header";
import Footer from "../Footer";
import { getCompanyDetail } from "../../api/main";

import logoMn from '../../assets/company info/Undesnii-zuvlugch-white.png';

const CompanyDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getCompanyDetail(id).then(res => {
      setData(res.data[0]);
    })
  }, [id]);

  const consultant = (items, id) => (
    <Box
      key={id}
      sx={{ width: '100%', flexGrow: 1 }}
    >
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} justifyContent="center">
        {items.map((row, index) =>
          <Grid key={index} item sx={{
            width: { xs: '50%', sm: '44%', md: '33%', lg: '25%', xl: '20%' }
          }}
          >
            <Box
              sx={{
                height: { xs: '18rem', sm: '18rem', md: '18rem', lg: '18rem', xl: '18rem', },
                backgroundColor: '#1b3b48', borderTopLeftRadius: '2rem', borderBottomRightRadius: '2rem'
              }}
            >
              <Box key={index} sx={{ height: '12rem', alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: "center", }} >
                <Box component="img"
                  alt="logo"
                  src={row.image}
                  sx={{ mt: '50%', mb: '10%', height: 'auto', maxHeight: '80%', width: 'auto', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}
                />
                <Box key={index} sx={{
                  alignItems: "center",
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center",
                }}
                >
                  <Typography sx={{ fontWeight: 'bold', color: 'white' }} align="center" >
                    {row.display_name}
                  </Typography>

                  <Typography sx={{ color: 'white', pl: 1, pr: 1 }} align="center" >
                    {row.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

    </Box>
  )


  return (
    <Box sx={{ backgroundColor: '#1b3b48', width: '100%' }}>
      <Header pageName="company" />
      <Box
        sx={{
          height: { xs: '10%', sm: '10%', md: '12%', lg: '13%', xl: '5%' },
          ml: { xs: '2%' },
          width: '100%',
          alignItems: "center",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center"
        }}
      >
        <Box
          component="img"
          alt="logo"
          src={logoMn}
          sx={{
            mt: 2,
            pb: 2,
            width: 'auto',
            maxWidth: {
              xs: '20%',
              sm: '16%',
              md: '13%',
              lg: '10%',
              xl: '9%'
            },
          }}
        />
        <Box
          sx={{
            height: '80%',
            width: { xs: '100%', sm: '96%', md: '80%', lg: '80%', xl: '80%' },
            mx: { xs: '0%', sm: '2%', md: '10%', lg: '10%', xl: '10%' },
            ml: { xs: '2%' },
            backgroundColor: '#ffffff',
            borderRadius: '3rem',
            display: 'flex',
            flexDirection: 'column',
            px: { xs: '3%', sm: '3%', md: '3%', lg: '3%', xl: '3%' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                my: '2%',
                ml: { xs: '5%', sm: '9%', md: '5%', lg: '5%', xl: '5%' },
                width: { xs: '45%', sm: '40%', md: '30%', lg: '30%', xl: '40%' },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: '10rem', sm: '10rem', md: '15rem', lg: '18rem', xl: '22rem' }
              }}>
              <Box
                component="img"
                alt="logo"
                src={data.logo_url}
                sx={{
                  height: {
                    xs: '80%',
                    sm: '80%',
                    md: '80%',
                    lg: '80%',
                    xl: '80%',
                  },
                  my: '2%'
                }}
              />
            </Box>
            <Box
              sx={{
                width: {
                  xs: data.logo_url !== '' ? '70%' : '100%',
                  sm: data.logo_url !== '' ? '70%' : '100%',
                  md: data.logo_url !== '' ? '70%' : '100%',
                  lg: data.logo_url !== '' ? '60%' : '100%',
                  xl: data.logo_url !== '' ? '50%' : '100%'
                },
                alignItems: "center",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {data.name}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }, ml: '5%', mr: '5%' }}>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Байгууллагын дэлгэрэнгүй
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }} >
              <Box
                sx={{
                  width: {
                    xs: 'auto',
                    sm: 'auto',
                    md: '100%',
                    lg: '100%',
                    xl: '100%'
                  },
                  align: 'justify',
                }}
              >
                <Typography sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >
                  {data.description && parse(data.description)}
                </Typography>
              </Box>
              <Box>
                <Box sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: { xs: 'auto', sm: 'auto', },
                  width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                  pl: { xs: '0', sm: '0%', md: '1%', lg: '1%', xl: '5%' }
                }}
                  mt={5}
                >
                  {data.image_url && <Box component="img" alt="logo" src={data.image_url} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{ width: '90%', display: 'flex', flexDirection: 'column', ml: '5%', mr: '5%' }}
          >
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' } }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                Байгууллагын дэлгэрэнгүй
              </Typography>
              <Box
                sx={{
                  width: {
                    xs: 'auto',
                    sm: 'auto',
                    md: '90%',
                    lg: '90%',
                    xl: '90%'
                  },
                  align: 'justify',
                }}
              >
                <Typography sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }} >
                  {data.description}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' } }}>

              <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white', textAlign: 'left' }}>
                Байгууллагын тогтолцоо
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                height: { xs: 'auto', sm: 'auto', },
                width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                pl: { xs: '0', sm: '0%', md: '1%', lg: '1%', xl: '5%' }
              }}
                mt={5}
              >
                <Box component="img" alt="logo" src={data.image_url}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Box>
          </Box> */}
          {data.consultants ? data.consultants.length !== 0 &&
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', pl: '5%', pr: '5%', pt: '2rem' }} mb={5}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                Манай зөвлөхүүд
              </Typography>
              {consultant(data.consultants, data.id)}
            </Box> : <></>
          }
        </Box>
      </Box>
      <Footer />
    </Box >
  );
}

export default CompanyDetailPage;