import axios from 'axios';

export const getConsultant = async lang => {
  const { data } = await axios.get(`https://nconsult.mn/api/consultant/${lang}`);
  return data;
}

export const getCompany = async lang => {
  const { data } = await axios.get(`https://nconsult.mn/api/company/${lang}`);
  return data;
}

export const getCompanyDetail = async id => {
  const { data } = await axios.get(`https://nconsult.mn/api/company-detail/${id}`);
  return data;
}