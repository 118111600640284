import { createSlice } from '@reduxjs/toolkit';

import { nconsult_consultants } from '../../data/base';

export const consultants_slice = createSlice({
    name : 'consultants',
    initialState: {
        value: []
    },
    reducers: {
        setConsultants: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { setConsultants } = consultants_slice.actions

export default consultants_slice.reducer