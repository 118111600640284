import { createSlice } from '@reduxjs/toolkit';

export const app_slice = createSlice({
    name : 'app',
    initialState: {
        value: {
            language : 'mn'
        }
    },
    reducers: {
        setLanguage: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { setLanguage } = app_slice.actions

export default app_slice.reducer