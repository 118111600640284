import { Button, CardActionArea, CardActions, Paper, Box, Divider, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../src/index.css';

import { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/modules';


// Images 
import hunschid from '../assets/partners_logo/hunschidiinkholboo.png';
import mhankook from '../assets/partners_logo/mhankook.png';
import mhhe     from '../assets/partners_logo/mhhebnh.png';
import mobicom  from '../assets/partners_logo/mobicom.png';
import moil     from '../assets/partners_logo/moil.png';
import simatai  from '../assets/partners_logo/simatai.jpg';
import stimo    from '../assets/partners_logo/stimo.jpg';
import unitel   from '../assets/partners_logo/unitel.png';

const theme = createTheme();

var items = [
    {
        name: "Хүнсчидийн Холбоо",
        description: "",
        img: hunschid
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: mhankook
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: mhhe
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: mobicom 
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: moil
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: simatai
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: stimo
    },
    {
        name: "L.Someone",
        description: "Some role",
        img: unitel
    }
]

let carosuel_show = 0;

function Item(props){
    return (
        <Box sx={{
                height: '5rem', 
                width: '100%', 
                display:"flex", 
                flexDirection: "column",
                justifyContent:"center", 
                alignItems:"center",
                m:1
            }}
        >
            <Box
                component="img"
                alt="Profile"
                src={props.item.img}
                sx={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    width: 'auto',
                    height: 'auto'
                }}
            >
            </Box>
        </Box>
    )
}

function PartnersSlider (){
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  
    if (isXs) {
        // Code for 'xs' screen size
        carosuel_show = 2;
    } else if (isSm) {
        // Code for 'sm' screen size
        carosuel_show = 2;
    } else if (isMd) {
        // Code for 'md' screen size
        carosuel_show = 3;
    } else if (isLg) {
        // Code for 'lg' screen size
        carosuel_show = 4;
    } else if (isXl) {
        // Code for 'xl' screen size
        carosuel_show = 5;
    }

    return (
        <>
            <Swiper
                slidesPerView={5}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                loop={true}
                modules={[Pagination, Autoplay, Navigation, Thumbs]}
                // className="mySwiper"
                style={{
                width: '100%',
                height: '100%',
            }}
            >
            {
                items.map((item, i) => <SwiperSlide sx={{objectFit: 'none'}}><Item key={i} item={item} /></SwiperSlide>)
            }
        </Swiper>
        </>
    );
}

export default PartnersSlider;