import { Button, CardActionArea, Card, CardActions, Paper, Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../app/slices/appSlice'

var subtitle_1 = {
  'en': 'Our law firm provides legal consulting services in the following areas.',
  'mn': "Манай хуулийн фирмээс дараах чиглэлд хууль зүйн зөвлөгөө үйлчилгээ үзүүлж байна.",
  'cn': '我们的律师事务所提供以下领域的法律咨询服务。'
}
var subtitle_1_1 = {
  'en': 'In advocacy',
  'mn': 'Өмгөөллийн чиглэлээр',
  'cn': '在宣传中'
}
var category_1 = [
  {
    title: {
      'en': 'Civil legal relations',
      'mn': 'Иргэний эрх зүйн харилцаа',
      'cn': '民事法律关系'
    },
    desc: {
      'en': 'Communication between citizens and organizations, as well as everyone involved in social relations, needs professional legal assistance. \n\n Lawyers and attorneys who have worked in their field for many years are involved in any contract, employment-related relationship, insurance, securities purchase, legal violations, debt, filing lawsuits, participating as a defendant or third party. providing advice and assistance.',
      'mn': "Иргэн болон байгууллага хооронд харилцахад цаашлаад нийгмийн харилцаанд оролцож буй хүн бүхэнд хууль зүйн  мэргэжлийн туслалцаа мэдлэг хэрэгтэй болдог. \n\n Салбартаа олон жил ажилласан тухайн чиглэлээр мэргэшсэн хуульч, өмгөөлөгчид аливаа гэрээ хэлцэл, хөдөлмөр эрхлэлттэй холбоотой харилцаатай, даатгал, үнэт цаас худалдан авах, эрх зүйн зөрчил, өр авлага, шүүхэд нэхэмжлэл гаргах, хариуцагч, гуравдагч этгээдээр оролцох зэрэг бүхий л харилцаанд зөвлөгөө, туслалцаа үзүүлж байна.",
      'cn': '公民与组织之间以及社会关系中的每个人之间的沟通都需要专业的法律帮助。 \n\n 在其领域工作多年的律师和代理人涉及任何合同、雇佣相关关系、保险、证券购买、违法行为、债务、提起诉讼、作为被告或第三方参与。 提供建议和帮助。'
    }
  },
  {
    title: {
      'en': 'Administrative legal relations',
      'mn': 'Захиргааны эрх зүйн харилцаа',
      'cn': '行政法律关系'
    },
    desc: {
      'en': 'Protecting legal interests from the decisions of state authorities and officials, giving advice, filing complaints to state officials to restore violated rights, filing lawsuits in administrative courts, participating in the enforcement of court decisions by providing advice, participating as a lawyer and representative providing legal assistance.',
      'mn': "Төрийн эрх бүхий байгууллага, албан тушаалтны шийдвэр үйл ажиллагаанаас хууль ёсны эрх ашгийг хамгаалах, зөвлөгөө өгөх, зөрчигдсөн эрхийг сэргээхээр төрийн байгууллага албан тушаалтанд өргөдөл гомдол гаргах, Захиргааны хэргийн шүүхэд нэхэмжлэл гаргах, шүүхийн шийдвэрийг албадан  биелүүлэхэд зөвлөгөө өгч оролцох, өмгөөлөгч, төлөөлөгчөөр оролцох зэргээр хууль эрх зүйн туслалцаа үзүүлж байна. ",
      'cn': '保护合法利益免受国家机关和官员决定的影响，提供建议，向国家官员提出申诉以恢复受侵犯的权利，向行政法院提起诉讼，通过提供建议参与法院决定的执行，作为律师和代表参与提供法律服务 协助。'
    },
  },
  {
    title: {
      'en': 'Criminal law relations',
      'mn': 'Эрүүгийн эрх зүйн харилцаа',
      'cn': '刑法关系'
    },
    desc: {
      'en': 'We provide legal consulting services by participating as a lawyer in participating as a victim, party member, as a civil plaintiff, or as a defendant in the criminal and violation laws, such as disputes between citizens and violations of honor and reputation.',
      'mn': "Гэмт хэрэг, зөрчил иргэд хоорондын маргаан болон нэр төр алдар нэр хүндэд халдах зэрэг эрүүгийн болон зөрчлийн тухай хуульд заасан гэмт хэрэг зөрчилд хохирогч, холбогдогчоор, иргэний нэхэмжлэгч, хариуцагчаар оролцоход өмгөөлөгчөөр оролцож хууль ёсны эрх ашгийг хамгаалах зэргээр хууль зүйн зөвлөгөө үйлчилгээ үзүүлж байна. ",
      'cn': '我们以律师身份参与作为受害人、党员、民事原告、被告人参与公民之间的纠纷、名誉侵权等刑事违法案件的法律咨询服务。'
    },
  }
]


var subtitle_2 = {
  'en': 'In the field of consulting services',
  'mn': "Зөвлөх үйлчилгээний чиглэлээр",
  'cn': '在咨询服务领域'
}
var subtitle_2_1 = {
  'en': 'It is a service that provides legal advice and assistance in advance for citizens and businesses to carry out any activity. It is a widely used international service that provides legal news and information in advance and provides advice from a professional team to completely prevent risks.',
  'mn': "Иргэн болон аж ахуйн нэгж аливаа үйл ажиллагаа явуулахад шаардлагатай хууль зүйн зөвлөгөө туслалцааг урьдчилан өгч ажилладаг үйлчилгээ юм. Хууль эрх зүйн мэдээ, мэдээллийг урьдчилж, мэргэжлийн багаас зөвлөгөө авснаар учирч болох эрсдэлээс бүрэн сэргийлэх боломж олгодог олон улсад ихээхэн дэлгэрсэн үйлчилгээ юм.",
  'cn': '这是一项为公民和企业开展任何活动提前提供法律建议和帮助的服务。 它是一项广泛使用的国际服务，提前提供法律新闻和信息，并由专业团队提供建议，彻底防范风险。'
}
var subtitle_2_2 = {
  'en': 'Regular legal advice can make business operations faster and safer.',
  'mn': 'Хууль зүйн зөвлөгөө үйлчилгээг тогтмол авснаар бизнесийн үйл ажиллагаа хурдан, аюулгүй байх боломжтой байдаг. ',
  'cn': '定期的法律咨询可以使企业运营更快、更安全。'
}
var category_2 = [
  {
    title: {
      'en': 'Financial Receivables Recovery Services',
      'mn': 'Авлага барагдуулах үйлчилгээ',
      'cn': '金融应收账款追收服务'
    },
    desc: {
      'en': 'It is a professional activity in which a professional team works on receivables from others and mutual accounts that arise regularly or incidentally when participating in social relations and conducting business activities.',
      'mn': "Нийгмийн харилцаанд оролцох, аж ахуйн үйл ажиллагаа явуулахад тогтмол болон тохиолдлын байдалтайгаар үүсдэг бусдаас авах авлага, харилцан тооцоон дээр мэргэжлийн баг ажиллаж хуулийн дагуу авлага барагдуулж гүйцэтгэлийн үр дүнд үйлчилгээний хөлс авдаг мэргэжлийн үйл ажиллагаа юм.",
      'cn': '它是由专业团队处理在参与社会关系和开展商业活动时经常或偶然产生的向他人应收账款和相互往来账款的专业活动。'
    },
  },
  {
    title: {
      'en': 'Research',
      'mn': 'Судалгаа',
      'cn': '研究'
    },
    desc: {
      'en': '"Finance and Business Law Research Institute" NGO is an academic council composed of academics and researchers established within the framework of the purpose of spreading legal and legal knowledge in the field of economics, deepening the understanding of economics and finance and business among lawyers, promoting and training them, and contributing to the development of these fields. , is a Non-Governmental Organization operating with development committees responsible for each sector.',
      'mn': "“Санхүү, бизнесийн эрх зүйн судалгааны хүрээлэн”  ТББ нь эдийн засгийн салбарт хууль, эрх зүйн мэдлэгийг түгээх, хуульчидад эдийн засаг, санхүү бизнесийн тухай ойлголтыг гүнзгийрүүлэх, сурталчлах, сургах, дээрх салбаруудын хөгжилд хувь нэмрээ оруулах зорилгын хүрээнд үүсгэн байгуулагдсан Эрдэмтэн судлаачдаас бүрдсэн эрдмийн зөвлөл, салбар бүрийг хариуцан хөгжүүлэх хороодтойгоор үйл ажиллагаа явуулж буй Төрийн Бус Байгууллага юм.",
      'cn': '“财经法研究会”是非政府组织，是一个由学者和研究人员组成的学术理事会，其宗旨是传播经济领域的法律和法律知识，加深律师对经济和金融和商业的理解，促进律师的发展。 并培训他们，并为这些领域的发展做出贡献。 是一个非政府组织，由负责各个部门的发展委员会运作。'
    },
  },
  {
    title: {
      'en': 'Compliance',
      'mn': 'Комплаенс',
      'cn': '遵守'
    },
    desc: {
      'en': 'Compliance is a global term, and there are common requirements to implement compliance internationally. For our country, it is also a system that creates an appropriate mechanism of action to protect against any risk, create control resolutions, and prevent corruption, money laundering, and financing of terrorism. Our country has adopted international standards as national standards. We are also carrying out tasks such as understanding the importance of compliance, providing assistance in its implementation, and external monitoring of its implementation.',
      'mn': "Комплаенс нь дэлхий нийтэд хэрэглэгддэг нэр томъёо бөгөөд олон улсад комплаенс хэрэгжүүлэхийг нийтлэг шаардлага тавигдаж байна. Манай улсын хувьд ч мөн аливаа эрсдэлээс хамгаалах, хяналтын тогтоолцоог бий болгох, авилга албан тушаалын гэмт хэрэг, мөнгө угаах, терроризмыг санхүүжүүлэхээс урьдчилан сэргийлэх зэрэг үйл ажиллагааны зохистой механизмыг бий болгодог тогтолцоо юм. Манай улс олон улсад хэрэгжүүлдэг стандартыг өөрийн орны стандарт болгон батлаад байна. Бид ч мөн комплаенсын ач холбогдлыг ойлгуулах, хэрэгжүүлэхэд нь туслалцаа үзүүлэх, хэрэгжилтэд нь хөндлөнгийн хяналт тавих зэрэг ажлыг хийж гүйцэтгэж байна.",
      'cn': '合规性是一个全球性术语，国际上实施合规性有共同的要求。 对于我们国家来说，它也是一个建立适当的行动机制来防范任何风险、制定控制决议并防止腐败、洗钱和恐怖主义融资的系统。 我国已采用国际标准作为国家标准。 我们还开展了解合规重要性、为合规实施提供协助以及对其实施情况进行外部监督等任务。'
    },
  },
  {
    title: {
      'en': 'Intellectual property',
      'mn': 'Оюуны өмч',
      'cn': '知识产权'
    },
    desc: {
      'en': 'In Mongolia, Intellectual Property is protected in the form of Industrial Property and Copyright, and is protected by the Law on Copyright, the Law on Patents, the Law on Trademarks and Geographical Indications, and is registered and protected in accordance with international agreements in force in Mongolia. . \n\nMongolia joined the Paris Convention for the Protection of Industrial Property in 1883, and is applying for registration within the member countries that have joined the convention and is working to protect it.',
      'mn': "Монгол улсад Оюуны өмч нь Аж үйлдвэрийн өмч, Зохиогчийн эрх гэсэн хэлбэрээр хамгаалагддаг бөгөөд Зохиогчийн эрхийн тухай хууль, Патентын тухай хууль, Барааны тэмдэг, газар зүйн заалтын тухай хуулиудаар хамгаалагддаг бөгөөд Монгол улсад хүчин төгөлдөр мөрдөгдөж байгаа болон Олон улсын гэрээ хэлэлцээрийн дагуу бүртгүүлж хамгаалж ажиллаж байна. \n\nМонгол улс Аж үйлдвэрийн өмчийг хамгаалах тухай Парисын конвенц /1883 он/ онд нэгдэн орсон байдаг бөгөөд тус конвенцод нэгдэн орсон гишүүн орнуудын хүрээнд бүртгэл хийлгэхээр хандаж, хамгаалуулан ажиллаж байна.",
      'cn': '在蒙古，知识产权以工业产权和版权的形式受到保护，并受版权法、专利法、商标和地理标志法的保护，并根据现行国际协定进行注册和保护 在蒙古。 。 \n\n蒙古于1883年加入《保护工业产权巴黎公约》，正在加入该公约的成员国内申请注册并致力于保护该公约。'
    },
  },
  {
    title: {
      'en': 'Tax',
      'mn': 'Татвар',
      'cn': '税'
    },
    desc: {
      'en': 'In accordance with Mongolian law, we provide all kinds of advice and services related to tax law training and implementation of activities to taxpayers, enterprises and registered and registered foreign enterprises. \n\nIn our team, we are providing comprehensive consulting services with Mongolia\'s Honored Economist, qualified tax consultant, qualified and experienced human resources who have worked in the field for many years.',
      'mn': "Монгол улсын хуулийн дагуу татвар төлөгч иргэн, аж ахуйн нэгж болон бүртгэлтэй болон бүртгэлтэй гадаадын иргэн аж ахуйн нэгжид татварын хууль тогтоомжийн сургалт болон үйл ажиллагаагаа хэрэгжүүлэхтэй холбоотой бүх төрлийн зөвлөгөө, үйлчилгээг үзүүлж байна. \n\nМанай багт Монгол улсын Гавъяат эдийн засагч, татварын мэргэшсэн зөвлөх, салбартаа олон жил ажилласан мэргэшсэн, туршлагатай хүний нөөцтэйгөөр зөвлөх үйлчилгээг цогцоор нь үзүүлж ажиллаж байна.",
      'cn': '根据蒙古国法律，我们向纳税人、企业以及注册登记的外国企业提供与税法培训和活动实施相关的各种咨询和服务。 \n\n我们的团队拥有蒙古国荣誉经济学家、合格税务顾问、在该领域工作多年的合格且经验丰富的人力资源，为您提供全面的咨询服务。'
    },
  },
  {
    title: {
      'en': 'Medical Juridical Practice',
      'mn': 'Эмнэлгийн эрх зүйн салбар',
      'cn': '医学法分支'
    },
    desc: {
      'en': 'We cooperate with the research team of the Union of Private Hospitals of Mongolia, the University of Medical Sciences, and the University of Traditional Medicine. Our lawyers, attorneys, and researchers specialize in the medical field and analyze the laws and legal violations in the field, provide advice and information, doing research.',
      'mn': "Монголын хувийн Эмнэлэгийн нэгдсэн холбоо, Анагаахын шинжлэх ухааны их Сургууль, Уламжлалт анагаах ухааны их сургуулийн судалгааны багтай хамтран ажилладаг бөгөөд манай хуульч, өмгөөлөгч, судлаачид эмнэлгийн салбараар мэргэшдэг бөгөөд тухайн салбарт мөрдөгдөж буй хууль тогтоомж, эрх зүйн зөрчилд дүн шинжилгээ хийх, зөвлөгөө мэдээлэл өгөх, судалгаа хийх зэргээр ажиллаж байна.",
      'cn': '我们与蒙古私立医院联盟、医科大学、传统医药大学的研究团队合作。 我们的律师、代理律师和研究人员专注于医疗领域，分析该领域的法律和违法行为，提供建议和信息，进行研究。'
    },
  }
]

function Operations() {
  // For language selection
  const language = useSelector((state) => state.app.value.language);
  const dispatch = useDispatch();

  return (
    <Box sx={{}}>
      {subtitle_1[language]}
      <Box sx={{
        display: 'flex',
        mt: 4,
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'column',
          xl: 'column'
        }
      }}
      >
        <Box sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          },
          alignItems: "left",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "justify",
        }}
        >
          <Typography variant="h6" >{subtitle_1_1[language]}</Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '20px',
          width: '100%',
          opacity: 0.1,
        }}>
          <Divider sx={{ width: '100%', border: '1px solid' }} />
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.2,
          ml: 4,
          mr: 4,
        }}>
          <Divider orientation='vertical'
            variant="middle" sx={{ border: '1px solid' }} />
        </Box>


        <Grid container spacing={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ height: 'auto' }}>
          {
            //#f37520
            category_1.map((item, index) => (
              <Grid item xs={2} sm={12} md={6} key={index}>
                <Paper sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderBottomLeftRadius: '2rem',
                  borderTopRightRadius: '2rem',
                }}
                >
                  <Box sx={{ p: 2, borderTopLeftRadius: '5px', borderTopRightRadius: '2rem', background: '#1b3b48' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {item.title[language]}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 2, pb: 2 }}>
                    <Typography variant="body2" sx={{ mt: 2, whiteSpace: 'pre-line', textAlign: 'justify' }}>
                      {item.desc[language]}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))
          }
        </Grid>
      </Box>

      <Box sx={{
        mt: 8, mb: 8, display: 'flex', flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'column',
          xl: 'column'
        }
      }}
      >
        <Box sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          },
          alignItems: "left",
          display: 'flex',
          justifyContent: "justify",
        }}
        >
          <Typography variant="h6" >{subtitle_2[language]}</Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '20px',
          width: '100%',
          opacity: 0.1,
        }}>
          <Divider sx={{ width: '100%', border: '1px solid' }} />
        </Box>

        <Typography variant="body" sx={{ textAlign: "justify", mt: 1, mb: 2 }}>
          {subtitle_2_1[language]}
        </Typography>

        <Typography variant="body" sx={{ textAlign: "justify", mb: 2 }}>
          {subtitle_2_2[language]}
        </Typography>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Divider orientation='vertical'
            variant="middle" sx={{ border: '1px solid' }} />
        </Box>


        <Grid container spacing={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ height: 'auto' }}>
          {
            category_2.map((item, index) => (
              <Grid item xs={2} sm={12} md={6} key={index}>
                <Paper sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderBottomLeftRadius: '2rem',
                  borderTopRightRadius: '2rem',
                }}
                >
                  <Box sx={{ p: 2, borderTopLeftRadius: '5px', borderTopRightRadius: '2rem', background: '#1b3b48' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {item.title[language]}
                    </Typography>
                  </Box>

                  <Box sx={{ px: 2, pb: 2 }}>
                    <Typography variant="body2" sx={{ mt: 2, whiteSpace: 'pre-line', textAlign: 'justify' }}>
                      {item.desc[language]}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Box>
  );
}

export default Operations;