import { useSearchParams, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import logoMn from '../../assets/company info/Undesnii-zuvlugch-white.png';
import logoEn from '../../assets/company info/Undesnii-zuvlugch-white-ENG.png'

import { useSelector, useDispatch } from 'react-redux';

import CustomFooter from '../Footer';
import Header from '../Header';
import axios from 'axios';
import moment from 'moment';
import parse from 'html-react-parser';

function NewsPage(props) {
  const language = useSelector((state) => state.app.value.language);
  const [data, setData] = useState({});
  const { id } = useParams();
  let config = {
    method: 'get',
    url: `https://nconsult.mn/api/news/detail/${id}`,
    // url: 'http://localhost:8000/api/news',
    headers: {}
  };

  useEffect(() => {
    axios.request(config)
      .then((response) => {
        console.log("response", response)
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [language])

  // useEffect(() => {

  // }, [language]);

  if (!data)
    return (<></>);

  return (
    <Box sx={{
      backgroundColor: '#1b3b48',
      width: '100%'
    }}>
      <Header pageName={"company"} />
      <Box key='top_app_bar' sx={{
        height: { xs: '10%', sm: '10%', md: '12%', lg: '13%', xl: '5%' },
        width: '100%',
        alignItems: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
      }}
      >
        <Box
          component="img"
          alt="logo"
          src={logoMn}
          sx={{
            mt: 2,
            pb: 2,
            width: 'auto',
            maxWidth: {
              xs: '20%',
              sm: '16%',
              md: '13%',
              lg: '10%',
              xl: '9%'
            },
          }}
        />
        <Box key='body' sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box key='body' sx={{
            height: '80%',
            my: 5,
            width: { xs: '100%', sm: '96%', md: '80%', lg: '80%', xl: '80%' },
            mx: { xs: '0%', sm: '2%', md: '10%', lg: '10%', xl: '10%' },
            backgroundColor: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            px: '3%',
          }}>
            <Typography variant='h4' m={3} style={{ textAlign: 'center' }} fontWeight={'bold'}>
              {data?.topic}
            </Typography>
            <Typography variant='h6' sx={{ textAlign: 'justify', textIndent: '2em' }}>
              {data?.description}
            </Typography>
            <div>
              <Typography alignSelf={'start'}>
                {moment(data?.postedat).format('LL')}
              </Typography>
              <Box
                component="img"
                alt="logo"
                src={data?.imageurl}
                sx={{ width: '100%', my: '2%' }}
              />
            </div>
            <Typography sx={{ textAlign: 'justify' }}>
              {data.content && parse(`${data?.content}`)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CustomFooter />
    </Box>
  );
}

export default NewsPage;
