import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Button, CardActionArea, Select, Menu, MenuItem, Paper, Box, Divider, Popper, Fade, Drawer } from '@mui/material';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import LanguageIcon from '@mui/icons-material/Language'; // Import the new LanguageIcon component
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

// Logo
import logoMn from '../assets/company info/Undesnii-zuvlugch-orange.png'
import logoEn from '../assets/company info/Undesnii-zuvlugch-orange-ENG.png'

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../app/slices/appSlice';

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={true} direction="down" in={trigger}>
			<div style={{ overflowX: 'hidden' }}>
				{children}
			</div>
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

const navItems = [
	{ text: { 'en' : 'HOME'       , 'mn' : 'НҮҮР'        , 'cn' : '家' }, id: 'home' },
	{ text: { 'en' : 'COMPANY'    , 'mn' : 'БАЙГУУЛЛАГА' , 'cn' : '公司' }, id: 'company' },
	{ text: { 'en' : 'CONSULTANTS', 'mn' : 'КОНСУЛТАНТ'  , 'cn' : '顾问' }, id: 'consultants' },
	{ text: { 'en' : 'PARTNERS'   , 'mn' : 'ХАМТРАГЧИД'  , 'cn' : '伙伴' }, id: 'partners' },
	{ text: { 'en' : 'SERVICES'   , 'mn' : 'ҮЙЛЧИЛГЭЭ'   , 'cn' : '服务' }, id: 'services' },
	{ text: { 'en' : 'CONTACT'    , 'mn' : 'ХОЛБОО БАРИХ', 'cn' : '接触' }, id: 'contact' },
];

const buttonStyles = {
	color: 'white',
	fontWeight: 'bold',
	textDecoration: 'none',
	position: 'relative',
	'&:hover::before': {
	  content: '""',
	  position: 'absolute',
	  width: '100%',
	  height: '2px',
	  bottom: 0,
	  left: 0,
	  backgroundColor: 'white',
	  animation: 'underline 0.3s forwards',
	},
  };

export default function Header(props) {
	const { pageName } = props;
	
	const theme = createTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('lg'));

	const location = useLocation();

	// For language selection
	const dispatch = useDispatch();
  	const language = useSelector((state) => state.app.value.language);

	const [open, setOpen] = useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// Drawer control
	const [DrawerState, setDrawerState] = useState(false);
	const toggleDrawer = (state) => (event) => {
		setDrawerState(state);
	};

	// Function to handle language change
	const handleLanguageChange = (language) => {
		dispatch(setLanguage({'language':language}))
		// Close the menu after selecting a language
		handleClose();
	};

	useEffect(() => {
		if (location.state && location.state.scrollToId) {
		  scroller.scrollTo(location.state.scrollToId, {
			duration: 500,
			delay: 0,
			smooth: 'easeInOutQuart',
			offset: -100,
		  });
		}
	  }, [location.state]);
						
	  const handleNavigate = (id) => {
		if(location.pathname !== '/')
			navigate('/', { state: { scrollToId: id } });
		else
			scroller.scrollTo(id, {
				duration: 500,
				delay: 0,
				smooth: 'easeInOutQuart',
				offset: -100,
			});

	  };

	const languageButtonComp = () => {
		return <>
			<Button
				onClick={handleClick}
				sx={buttonStyles}
			>
				<LanguageIcon /> {/* Use the LanguageIcon component here */}
			</Button>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => handleLanguageChange('en')}>
					English
				</MenuItem>
				<MenuItem onClick={() => handleLanguageChange('mn')}>
					Mongolian
				</MenuItem>
				<MenuItem onClick={() => handleLanguageChange('cn')}>
					Chinese
				</MenuItem>
			</Menu>
		</>
	}

	
	const drawerComponent = () => {
		return <Box>
			
			<Drawer
				anchor="right"
				open={DrawerState}
				onClose={toggleDrawer(false)}
				sx={{
					width: { xs:'90%',sm: '50%'},
				}}
			>
				{
					navItems.map((item) => (
						(pageName !== 'home' && item.id !== 'contact' ) ? (
							<Button
								sx={{
									position: 'relative',
									color: 'black',
									fontWeight: 'bold', textDecoration: 'none',
									'&:hover::before': {
										content: '""', 
										bottom: 0, left: 0, position: 'absolute',
										width: '100%', height: '2px',
										backgroundColor: 'white',
										animation: 'underline 0.3s forwards',
									},
									mt: 2
								}}
								onClick={() => {
									if(item.id=='home'){
										toggleDrawer(false);
										navigate('/');
									}else{
										toggleDrawer(false);
										navigate('/'+item.id);
									}

								}}
								>
								<Typography sx={{ color: 'black', mx: 1 }}>
									{item.text[language]}
								</Typography>
								<style>
								{`
									@keyframes underline {
									from {
										width: 0;
									}
									to {
										width: 100%;
									}
									}
								`}
								</style>
							</Button>
						) : (
						<Link
							activeClass="active" 
							to={item.id} spy={true} smooth={true} 
							offset={-100} duration={500}
						>
							<Button
								sx={{
									color: 'black',
									fontWeight: 'bold',
									textDecoration: 'none',
									position: 'relative',
									'&:hover::before': {
										content: '""',
										position: 'absolute',
										width: '100%', height: '2px',
										bottom: 0, left: 0,
										backgroundColor: 'white',
										animation: 'underline 0.3s forwards',
									},
									mt:2
								}}
								onClick={toggleDrawer(false)}
							>
								<Typography sx={{ color: 'black', mx: 1 }}>
								{
									item.text[language]
								}
								</Typography>
							</Button>
							<style>
								{`@keyframes underline {
									from { width: 0; }
									to { width: 100%; } 
								}`}
							</style>
						</Link>
						)
					))					
				}
			</Drawer>
		</Box>
	}

	const topAppBarComponent = () => {
		return <Box sx={{ 
			zIndex: 900,
			display: { xs: 'none',sm:'none',md:'block',lg:'block',xl:'block'},
			alignItems: 'right' 
		}}>
			{
				navItems.map((item) => (
						(pageName !== 'home' && item.id !== 'contact' ) ? (
						<Button
							sx={{
								position: 'relative',
								color: 'white',
								fontWeight: 'bold', textDecoration: 'none',
								'&:hover::before': {
									content: '""', 
									bottom: 0, left: 0, position: 'absolute',
									width: '100%', height: '2px',
									backgroundColor: 'white',
									animation: 'underline 0.3s forwards',
								},
							}}
							onClick={() => {
								handleNavigate(item.id);
								// if(item.id == 'home'){
								// 	navigate('/');
								// }else{
								// 	navigate('/'+item.id);
								// }

							}}
							>
							<Typography sx={{ color: 'white', mx: 1 }}>
								{item.text[language]}
							</Typography>
							<style>
							{`
								@keyframes underline {
								from {
									width: 0;
								}
								to {
									width: 100%;
								}
								}
							`}
							</style>
							</Button>


					) : (
					<Link
						activeClass="active"
						to=''
						onClick={() => handleNavigate(item.id)}
						spy={true} smooth={true} 
						offset={-100} duration={500}
					>
						<Button
							sx={{
							color: 'white',
							fontWeight: 'bold',
							textDecoration: 'none',
							position: 'relative',
							'&:hover::before': {
								content: '""',
								position: 'absolute',
								width: '100%', height: '2px',
								bottom: 0, left: 0,
								backgroundColor: 'white',
								animation: 'underline 0.3s forwards',
							},
							}}
						>
							<Typography sx={{ color: 'white', mx: 1 }}>
							{
								item.text[language]
							}
							</Typography>
						</Button>
						<style>
							{`@keyframes underline {
								from { width: 0; }
								to { width: 100%; } 
							}`}
						</style>
					</Link>
					)
				))
			}
			{ languageButtonComp() }
		</Box>
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<HideOnScroll {...props}>
				<AppBar position="fixed" style={{ backgroundColor: 'rgba(27, 59, 72, 0.9)',  zIndex: 1000  }} 
					sx={{
						pl: { xs:0,sm:3,md:20,lg:20,xl:20},
						pr: { xs:0,sm:0,md:10,lg:10,xl:10},
						maxHeight: '9%'
					}}
				>
					<Toolbar sx={{ display: 'flex', 
						justifyContent: { xs:'space-between' ,sm:'space-between',md:'space-between',lg:'space-between',xl:'space-between'}, 
						alignItems: 'center',
						alignContent: 'center'
					}}>
						
						{
							// If it is XS or SM it should show the language option first
							(isXs)
							?	<Box sx={{ 
									display: 'block',
									alignItems: 'left' 
								}}>
									<Button
										onClick={handleClick}
										sx={buttonStyles}
									>
										<LanguageIcon /> {/* Use the LanguageIcon component here */}
									</Button>
									<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
										<MenuItem onClick={() => handleLanguageChange('en')}>
											English
										</MenuItem>
										<MenuItem onClick={() => handleLanguageChange('mn')}>
											Mongolian
										</MenuItem>
										<MenuItem onClick={() => handleLanguageChange('cn')}>
											Chinese
										</MenuItem>
									</Menu>
								</Box>
							: <></>
						}

						{
							//Fill the space
							(isXs) ?
								<Box sx={{flexGrow: 1}}></Box>
								: <></>
						}

						<Box sx={{  width:  { xs:'28%' ,sm:'16%',md:'15%',lg:'10%',xl:'10%'}, }}>
							<img
								src={ language == 'mn' ? logoMn : logoEn}
								alt="Image Description"
								style={{ maxWidth: '100%', height: '50px' }}
							/>
						</Box>
						
						{
							//Fill the space
							(isSm || isXs) ?
								<Box sx={{flexGrow: 1}}></Box>
								: <></>
						}

						<Box sx={{ 
							display: 'block',
							alignItems: 'right',
						}}>
							{
								(isSm)
								?	<>
										<Button
											onClick={handleClick}
											sx={buttonStyles}
										>
											<LanguageIcon /> {/* Use the LanguageIcon component here */}
										</Button>
										<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
											<MenuItem onClick={() => handleLanguageChange('en')}>
												English
											</MenuItem>
											<MenuItem onClick={() => handleLanguageChange('mn')}>
												Mongolian
											</MenuItem>
											<MenuItem onClick={() => handleLanguageChange('cn')}>
												Chinese
											</MenuItem>
										</Menu>
									</>
								: <></>
							}

							{
								// If it is XS or SM it should show the language option first
								(isXs || isSm)
								?	<Button onClick={toggleDrawer(true)} sx={buttonStyles}>
										<MenuIcon />
									</Button>
								: <></>
							}
						</Box>

						{
							// whether to load drawer
							(isXs || isSm)
							?	drawerComponent()
							:	topAppBarComponent()
						}
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar />
		</React.Fragment>
	);
} 
