import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import CompanyDetailPage from './pages/company/CompanyDetailPage';
import ConsultantPage from './pages/consultant/ConsultantPage';
import NewsPage from './pages/news/NewsPage';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/company/:id" element={<CompanyDetailPage />} />
				<Route path="/news/:id" element={<NewsPage />} />
				<Route path="/consultant/:consultantName" element={<ConsultantPage />} />
				<Route path="*" element={<p>No Match</p>} />
			</Routes>
		</>
	);
}

export default App;
