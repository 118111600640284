import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Paper, Grid, Button, CardActionArea, Dialog, DialogContent, CardActions, Container } from '@mui/material';
import { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../app/slices/appSlice';

// Responsive widgets
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import data
import { company_list } from '../data/base';

const theme = createTheme();

theme.typography.h5 = {
	[theme.breakpoints.up('xs')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('sm')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '1.4rem',
	},
	[theme.breakpoints.up('xl')]: {
		fontSize: '22px',
	},
};

let selection = 0;

function CompNews(props) {

	const language = useSelector((state) => state.app.value.language);
	const [data, setData] = useState([]);

	const navigate = useNavigate();

	const handleOpen = (id) => {
		selection = id - 1;
		navigate(`/news/${id}`);
	};

	let config = {
		method: 'get',
		url: `https://nconsult.mn/api/news/${language}`,
		headers: {}
	};

	useEffect(() => {
		axios.request(config)
			.then((response) => {
				setData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [language])

	const GeneralSectors = ({ key, item }) => {
		return (
			<Box
				onClick={() => { handleOpen(item.id) }}
				sx={{
					width: { xs: '100%', sm: '100%', md: '80%', lg: '80%', xl: '80%' },
					height: '100%',
					// flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
					position: 'relative',
					m: 2
				}}
			>
				<Card elevation={2}
					sx={{
						borderRadius: '10px',
						// width: { xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%', },
						// ml: { xs: '12%', sm: '12%', md: '12%', lg: '12%', xl: '12%' },
					}}
				>
					<img
						src={item.imageurl}
						style={{
							height: '200px',
							width: '100%',
							objectFit: 'cover'
						}}
					/>
					{/* <CardMedia
						component="img"
						image={item.imageurl}
						alt={item.id}
						sx={{
							width: 200,
							height: 100,
							objectFit: 'cover'
						}}
					/> */}
					<CardActionArea sx={{ height: '100%', position: 'relative' }}>
						<CardContent>
							<ThemeProvider theme={theme}>
								<Typography gutterBottom variant="h5" component="div">
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{
										height: '40px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: '-webkit-box',
										WebkitLineClamp: 3,
										WebkitBoxOrient: 'vertical',
										lineHeight: '1.2em',
									}}
								>
									{item.description}
								</Typography>
							</ThemeProvider>
						</CardContent>
					</CardActionArea>
				</Card>

				{/* <Box
					sx={{
						ml: { xs: '0', sm: '2%', md: '5%', lg: '0', xl: '1%' },
						width: { xs: '100px', sm: '110px', md: '120px', lg: '120px', xl: '27%' },
						position: 'absolute',
						top: '10%',
					}}
				>
					{
						<Card
							sx={{
								height: {
									xs: '10rem',
									sm: '12rem',
									md: '12rem',
									lg: '10rem',
									xl: '12rem'
								},
								width: {
									xs: '8rem',
									sm: '9rem',
									md: '8rem',
									lg: '7rem',
									xl: '7rem'
								},
								borderRadius: '28px',
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								bgcolor: item.id==2 ? '#23408e' : '#ffffff'
							}}
						>
							<CardMedia
								component="img"
								image={item.comps[0].logo}
								alt={item.comps[0].name}
								sx={{
									height: '70%',
									width: '100%',
								}}
							/>

						</Card>

					}

				</Box> */}

			</Box>
		);
	}

	return (
		<Box width={'100%'} justifyContent="center" alignItems="center"
			sx={{
				// flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
				mt: 2,
				mb: 2
			}}
		>
			{/* <Swiper
				// slidesPerView={4}
				breakpoints={{
					576: {
						slidesPerView: 2,
						spaceBetween: 20
					},
					992: {
						slidesPerView: 2,
						spaceBetween: 30
					},
					1400: {
						slidesPerView: 2,
						spaceBetween: 40
					}
				}}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				loop={true}
				modules={[Pagination, Autoplay, Navigation, Thumbs]}
				style={{
					display: 'flex',
					width: '100%',
					height: '100%',
					flexDirection: 'row',
				}}
			>
				{data.map((item, i) => (
					<SwiperSlide key={i}>
						<GeneralSectors item={item} />
					</SwiperSlide>
				))}
				</Swiper> */}
			<Swiper
				breakpoints={{
					900: {
						slidesPerView: 2,
						spaceBetween: 20
					},
					1400: {
						slidesPerView: 3,
						spaceBetween: 30
					},
					2000: {
						slidesPerView: 4,
						spaceBetween: 40
					}
				}}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				loop={true}
				modules={[Pagination, Autoplay, Navigation, Thumbs]}
				// className="mySwiper"
				style={{
					display: 'flex',
					width: '100%',
					height: '100%',
					flexDirection: 'row',
				}}
			>
				{
					data.map((item, i) =>
						<SwiperSlide sx={{ objectFit: 'none' }}>
							<GeneralSectors key={i + 'CustomCard'} item={item} />
						</SwiperSlide>
					)
				}
			</Swiper>
		</Box>
	);
}

export default CompNews;