import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Redux
import store from './app/store'
import { Provider } from 'react-redux'

// Routing
import { BrowserRouter } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material/styles';

// Apollo
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_URL,
  cache: new InMemoryCache()
});
console.log()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
              <App />
          </StyledEngineProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>
);
